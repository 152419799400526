<template>
  <div class="row">

    <div class="col-12">
      <div class="row">
        <div class="col-12 border-right" style="height: 800px; overflow-y: scroll;">
          <b-badge variant="light">ECDIS</b-badge>
          <br><br>
          <Ecdis v-model="items" :fields="fields"></Ecdis>
        </div>

<!--        <div class="col-4 border-right" style="height: 800px; overflow-y: scroll;">-->
<!--          <b-badge variant="light">DETAILS</b-badge>-->
<!--          <br/><br/>-->
<!--          <Details v-model="items" :fields="fields"></Details>-->
<!--        </div>-->

      </div>
    </div>

  </div>
</template>

<script>
import Ecdis from "@/views/ecdis/Ecdis.vue";
import Details from "@/views/ecdis/Details.vue";
import {eventBus} from "@/main";

export default {
  components: {Ecdis, Details},
  data() {
    return {
      fields: [
        {key: 'email', label: 'Email', type: 'email', sortable: false, tdClass: 'small-black', thClass: 'small-gray'},
        {key: 'ship_name', label: 'Ship', type: 'text', sortable: false, tdClass: 'small-black', thClass: 'small-gray'},
        {key: 'ship_imo', label: 'IMO', type: 'text', tdClass: 'small-black', thClass: 'small-gray'},
        // {key: 'imo', label: 'IMO', type: 'number', tdClass: 'small-black', thClass: 'small-gray'},
        // {key: 'mmsi', label: 'MMSI', type: 'number', tdClass: 'small-black', thClass: 'small-gray'},
        {
          key: 'ship_category', label: 'Category', type: 'select',
          options: [
            'Barge',
            'WMS',
            'Vehicle Carrier',
            'Combination',
            'Container',
            'Dredger',
            'Icebreaker',
            'Not a Vessel',
            'Naval Vessel',
            'Passenger Vessel',
            'Pilot',
            'Research Vessel',
            'Reefer',
            'Ro-Ro',
            'Salvage Vessel',
            'Survey Vessel',
            'Tug',
            'Vessel Traffic System',
            'Dry Cargo/Passenger',
            'Fishing',
            'Bulk Carriers',
            'Offshore',
            'Miscellaneous',
            'Tankers',
            'Inland Waterways',
            'Non-Merchant Ships',
            'Non Propelled',
            'Non Ship Structures'
          ],
          tdClass: 'small-black', thClass: 'small-gray'
        },
        {
          key: 'ecdis_brand', label: 'ECDIS Brand', type: 'select',
          options: [
            'dKart',
            'FURUNO',
            'GEM Elettronica',
            'Kelvin Hughes',
            'Kongsberg Maritime',
            'NAUDEQ',
            'Navis USA LLC',
            'PC Maritime',
            'Raytheon Anschütz',
            'Seatronx',
            'Sodena',
            'Sperry Marine',
            'TOKIO KEIKI',
            'Transas',
            'JRC',
            'Unknown'],
          tdClass: 'small-black', thClass: 'small-gray'
        },
        {
          key: 'ecdis_os', label: 'ECDIS OS', type: 'select',
          options: [
            'Windows',
            'Linux',
            'Android'],
          tdClass: 'small-black', thClass: 'small-gray'
        },
        {
          key: 'ecdis_type', label: 'ECDIS Type', type: 'select',
          options: [
            'Primary',
            'Backup',],
          tdClass: 'small-black', thClass: 'small-gray'
        },
        {key: 'permit', label: 'Permit', type: 'text', tdClass: 'small-black', thClass: 'small-gray'},
        {key: 'edit', label: '', type: 'edit'}
      ],
      items: [
      ]
    }
  },
  methods: {},
}
</script>
