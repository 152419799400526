import L from "leaflet";
import {MyLeaf} from "./my_leaf";
import store from "@/store";
import * as utils from "@/utils/utils";
import {eventBus} from "@/main";
import 'leaflet.polylinemeasure/Leaflet.PolylineMeasure'
import 'leaflet.polylinemeasure/Leaflet.PolylineMeasure.css'
import 'leaflet-rotatedmarker'
import 'leaflet-draw'
import 'leaflet-spin';
import 'leaflet-contextmenu-bootstrap'
import 'leaflet-contextmenu-bootstrap/dist/leaflet.contextmenu.min'
import {CURRENT_PRODUCT, NAVICOM} from "@/utils/build-config";


export class NavLeaf extends MyLeaf {
    constructor() {
        super();
        this.markerLayer = L.layerGroup();
        this.shipData = {};
    }
    init(el){
        let url = store.state.charts.map.url;
        let center = store.state.leaflet.map.lat === -1 ? [58.062411, 6.836087] : [store.state.leaflet.map.lat, store.state.leaflet.map.lon];
        let zoom = store.state.leaflet.map.zoom === -1 ? 7 : store.state.leaflet.map.zoom;

        // console.log("---------")
        // console.log("---------")
        // console.log("---------")
        // console.log("---------")
        // console.log(localStorage.getItem('mapCenter'))
        // console.log(localStorage.getItem('mapZoom'))

        // let center = !localStorage.getItem('mapCenter') ? [58.062411, 6.836087] : localStorage.getItem('mapCenter');
        // let zoom = !localStorage.getItem('mapZoom') ? 7 : localStorage.getItem('mapZoom');

        super.init(el, url, center, zoom, 4, 17);

        let tooltipPane = this.map.createPane('aaa');
        tooltipPane.style.zIndex = 810

        const navURL = "https://ntiles.bgeo.fi:6001/map/{z}/{x}/{y}";


        if (!CURRENT_PRODUCT.is_navicom) {
            this.navalLayer = L.tileLayer(navURL, {
                minZoom: 4,
                maxZoom: 17,
            }).addTo(this.map)
        }

        this.multiTilesStateControl = new L.Control.EasyButton({
            states: [{
                stateName: 'on',        // name the state
                icon:      'bi bi-toggle-on',               // and define its properties
                title:     'Toggle S63 tiles',      // like its title
                onClick: (btn) => {       // and its callback
                    this.navalLayer.remove();
                    btn.state('off');    // change state on click!
                }
            }, {
                stateName: 'off',
                icon:      'bi bi-toggle-off',
                title:     'Toggle S63 tiles',
                onClick: (btn) => {
                    this.navalLayer.addTo(this.map);
                    btn.state('on');
                }
            }]
        }).addTo(this.map)

        let shipPane = this.map.createPane('sss');
        tooltipPane.style.zIndex = 800

        this.markerLayer.addTo(this.map);

        eventBus.$on('remove-primar-tiles', () => {
            this.navalLayer.remove();
        })

        eventBus.$on('restore-primar-tiles', () => {
           this.navalLayer.addTo(this.map);
        })


    }
    daysDiff(value, item) {
        if (item.appState.isActive) {
            return 0;
        }
        return utils.daysOld(value);
    }
    showAppState(aindex, lat, lon, azimuth, id, isActive, name, route, sog, email, gpsDate, appStateDate, routeJson=[], posbyship=false) {
        this.shipData[aindex] = {};

        this.shipData[aindex].route = routeJson;
        // if (routeJson.length > 0) store.commit('routes/setRoutePoints', routeJson);

        let daysOld = utils.daysOld(gpsDate);

        let iconByDays = require("../assets/ship_1tx_green.png");
        if (!isActive && daysOld > 30) {
            iconByDays = require("../assets/ship_1tx_grey.png");
        } else if (!isActive && daysOld > 7) {
            iconByDays = require("../assets/ship_1tx_cyan.png");
        } else if (!isActive && daysOld > 1) {
            iconByDays = require("../assets/ship_1tx_red.png");
        } else if (!isActive) {
            iconByDays = require("../assets/ship_1tx_yellow.png");
        }

        var icon = L.icon({
            iconUrl: iconByDays,
            iconSize: [20, 40],
            iconAnchor: [10, 20],

        });
        this.shipData[aindex].marker = new L.marker([lat, lon], {icon: icon, rotationAngle: azimuth, aindex: aindex, deviceid: id, pane: 'sss'});
        var marker = this.shipData[aindex].marker;
        let date = new Date(gpsDate)
        let local = date.toLocaleString()

        let utcLocal = "";
        if (date.getFullYear() < 2000) {
            local = "unknown";
            utcLocal = new Date(appStateDate).toLocaleString()
        }

        let tooltip = "id: " + marker.options.deviceid;
        if (name) tooltip = "name: " + name;
        if (email) tooltip += "<br>" + "user: " + email;
        if (route) tooltip += "<br>" + "route: " + route;
        if (sog && sog != "-3000") tooltip += "<br>" + "sog: " + sog + " kNots";
        if (gpsDate) tooltip += "<br>" + "last activity: " + local;
        if (utcLocal) tooltip += "<br>" + "last appstate: " + utcLocal;
        // console.log(days)

        marker.bindTooltip(tooltip, {pane: 'aaa'});

        marker.addTo(this.markerLayer);
        if (posbyship) {
            marker.on('click', () => {
                // console.log(file, 'shtp!')
                eventBus.$emit('ship-clicked', marker.options.aindex);
            })
        }
    }
    clearAppStates(){
        this.markerLayer.clearLayers();
    }
    clearRoute(){
        if (this.pMeasure) {
            // console.log("remove")
            if (this.pMeasure._layerPaint) {
                this.pMeasure._layerPaint.clearLayers();
            }
            this.pMeasure.remove()
        }
    }
    add_pMeasure(drawing = false){
        if(this.pMeasure){
            delete this.pMeasure;
        }
        if (drawing){
            this.pMeasure = L.control.polylineMeasure({
                clearMeasurementsOnStop: true,
                showUnitControl: false,
                showBearings: false,
                showClearControl: true,
                clearControlTitle: "Cancel current route",
                measureControlTitleOn: "Start drawing route",
                measureControlTitleOff: "Return to manual selecting",
                unit: 'nauticalmiles',
                pane: 'aaa'
            });
        }
        else {
            this.pMeasure = L.control.polylineMeasure({
                clearMeasurementsOnStop: false,
                showUnitControl: false,
                unit: 'nauticalmiles',
                pane: 'aaa'
            });
        }
        this.pMeasure.addTo(this.map);
        let a = document.getElementById('polyline-measure-control')
        a.hidden = true;

    }
    paneToShip(aindex, id, name, route, sog, email, isActive, days) {
        let marker = this.shipData[aindex].marker;
        let lat = marker._latlng.lat;
        let lon = marker._latlng.lng;
        this.moveTo(lat, lon);
        this.clearRoute();
        if (this.shipData[aindex].route.length > 1) {
            this.add_pMeasure();
            let pts = []
            this.shipData[aindex].route.forEach(pt => {
                pts.push({
                    lat: pt.latDegree,
                    lon: pt.lonDegree
                })
            })
            store.commit("routes/setRoutePoints", pts)
        }

        if (this.pMeasure && this.shipData[aindex].route.length > 1) {

            this.showRoute(aindex);
        }
        let x = setTimeout(() => {
            marker.openTooltip()
        }, 500)
        Object.keys(this.shipData).forEach(key => {
            // NOTE: DO NOT ===/!== with aindex
            if (key != aindex && this.shipData[key].marker){
                this.shipData[key].marker.closeTooltip();
            }
        })
    }
    showRoute(aindex){
        let pts = [];
        this.shipData[aindex].route.forEach((pt) => {
            pts.push({lat: pt.latDegree, lng: pt.lonDegree});
            console.log(pt.latDegree, pt.lonDegree)
        })
        // toggle draw state on:
        this.pMeasure._toggleMeasure();
        // start line with first point of each polyline
        this.pMeasure._startLine(pts[0]);
        // add subsequent points:
        pts.forEach((point, ind) => {
            const latLng = L.latLng(point);
            this.pMeasure._mouseMove({ latLng });
            this.pMeasure._currentLine.addPoint(latLng);
            // on last point,
            if (ind === pts.length - 1) {
                this.pMeasure._finishPolylinePath();
                this.pMeasure._toggleMeasure();
            }
        });
    }
}