<template>
  <div>

    <b-card v-if="$store.state.routes.route.length > 0" class="mb-2 small-black" bg-variant="light" text-variant="black" header="ROUTE">
      <span class="small-gray-card">Length (m):</span> {{ $store.state.routes.length}}<br>

      <div v-if="isDangerous()">
        <span class="small-gray-card">Safety:</span> <b-badge variant='danger'>Danger</b-badge><br>
      </div>
      <div v-else>
        <span class="small-gray-card">Safety:</span> <b-badge variant='success'>OK</b-badge><br>
      </div>
    </b-card>

    <b-form-select v-model="selected" :options="options"></b-form-select>

    <b-button-group size="sm" class="mt-3">
      <b-dropdown variant="info" right text="Route">
        <b-dropdown-item>New</b-dropdown-item>
        <b-dropdown-divider></b-dropdown-divider>
        <b-dropdown-item>Load</b-dropdown-item>
        <b-dropdown-item>Save</b-dropdown-item>
        <b-dropdown-item>Edit</b-dropdown-item>
      </b-dropdown>
    </b-button-group>

    <br><br>
    <b-form-group label-cols="4" label-size="sm" label="Start time">
      <b-form-timepicker v-model="plan.startTime" locale="en"></b-form-timepicker>
    </b-form-group>

    <b-form-group label-cols="4" label-size="sm" label="Speed (knots)">
      <b-form-spinbutton id="sb-inline-height" v-model="plan.speed" min="1" step="1" max="50" locale="en"></b-form-spinbutton>
    </b-form-group>

    <RouteTable v-model="items" :fields="fields"></RouteTable>

  </div>
</template>

<script>

import RouteTable from "@/views/routes/RouteTable.vue";

export default {
  components: {RouteTable},
  data() {
    return {
      selected: null,
      options: [
        { value: null, text: 'Please select a route' },
        { value: 'a', text: 'route 1' },
        { value: 'b', text: 'route 2' },
        { value: 'c', text: 'route 3' },
      ],
      plan: {
        startTime: '',
        speed: 10,
      },
      fields: [
        {key: 'index', label: 'WP', type: 'number', sortable: true, tdClass: 'small-black', thClass: 'small-gray'},

        {key: 'lat', label: 'Lat', type: 'number', tdClass: 'small-black', thClass: 'small-gray', },
        {key: 'lon', label: 'Lon', type: 'number', tdClass: 'small-black', thClass: 'small-gray', },

        {key: 'radius', label: 'Radius', type: 'number', tdClass: 'small-black', thClass: 'small-gray', },
        {key: 'xteLeft', label: 'XTE.L', type: 'number', tdClass: 'small-black', thClass: 'small-gray'},
        {key: 'xteRight', label: 'XTE.R', type: 'number', tdClass: 'small-black', thClass: 'small-gray'},
        {key: 'edit', label: '', type: 'edit' }
      ],

      // items: [
      //   {index: '1', radius: '10', xte_l: '100', xte_r: '210'},
      //   {index: '2', radius: '11', xte_l: '110', xte_r: '230'},
      //   {index: '3', radius: '12', xte_l: '120', xte_r: '240'},
      // ]
    }
  },
  computed: {
    items(){
      return this.$store.state.routes.route
    }
  },
  methods: {
    isDangerous() {
      let val = false;

      for (let i = 0; i < this.$store.state.routes.dangers.length; i++) {
        if (this.$store.state.routes.dangers[i].isDanger) {
          val = true;
          break;
        }
      }
      return val;
    }
  }
}
</script>
