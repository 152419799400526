<template>
  <div>
    <b-badge variant="light">DETAILS</b-badge>
    <br><br>

    <div>
      <b-overlay :show="$store.state.routes.progress.isCalculating" rounded="sm">

        <div id="mymap" ref="mm" :style="{ height: height + 'px'}"></div>

        <template #overlay>
          <div  class="text-center">
            <p id="cancel-label">Please wait...</p>

            <b-progress class="mt-2" :max=100 show-value show-progress animated>
              <b-progress-bar :value="$store.state.routes.progress.calculatingProgress" variant="success"></b-progress-bar>
            </b-progress>
          </div>
        </template>
      </b-overlay>

    </div>
  </div>
</template>

<script>
import {eventBus} from "@/main";

export default {
  props: ["map", "height"],

  mounted() {
    this.map.init(this.$refs.mm);
  },
  methods: {
  }
}
</script>
<style scoped>
#mymap{
  z-index: 1;
}
</style>
