<template>
  <div>
    <div class="px-3 py-2 deb1">

      <div v-if="$store.state.auth.user && $store.state.auth.user.displayId">Primar user ID: <code>{{ $store.state.auth.user.displayId }}</code><br></div>
      <div v-if="$store.state.charts.profile.device_id">Device ID: <code>{{ $store.state.charts.profile.device_id }}</code><br></div>
      <div v-if="$store.state.charts.profile.user_id">User ID: <code>{{ $store.state.charts.profile.user_id }}</code><br></div>
      <div v-if="$store.state.charts.profile.device_email">Email: <code>{{ $store.state.charts.profile.device_email }}</code><br></div>

      <div v-if="$store.state.manage.appstate && $store.state.manage.appstate.programInfo && $store.state.manage.appstate.programInfo.activeProductId">
        Active product: <code>{{ $store.state.manage.appstate.programInfo.activeProductId }}</code>
      </div>

      <div v-if="$store.state.current.product.current">Product: <code>{{ $store.state.current.product.current }}</code><br></div>
      <div v-if="$store.state.debug.route.url">Route URL: <code>{{ $store.state.debug.route.url }}</code><br></div>
      <div v-if="$store.state.debug.route.update_url">Update URL: <code>{{ $store.state.debug.route.update_url }}</code><br></div>
      <div v-if="$store.state.debug.route.update_data">Update Data: <code>{{ $store.state.debug.route.update_data }}</code><br></div>
      <div v-if="$store.state.debug.route.response">Route Response: <code>{{ $store.state.debug.route.response.data }}</code><br></div>

      <div v-if="$store.state.current.loading.loadingMarkers.length > 0">
        <hr>
        {{ $store.state.current.loading.loadingMarkers }}
      </div>

    </div>
  </div>
</template>

<script>

export default {}
</script>