<template>
  <div>

    <b-modal id="modal-1" title="Confirm" v-model="openDialog" ok-title="Remove" @ok="removeRowHandler">
      <p class="my-4">Are you sure you want to remove the selected rows?</p>
    </b-modal>

    <div>
      <b-table small sort-icon-left hover selectable select-mode="single" @row-selected="onRowSelected" :items="tableItems" :fields="fields">
        <template v-for="(field, index) in fields" #[`cell(${field.key})`]="data">
          <b-form-datepicker v-if="field.type === 'date' && tableItems[data.index].isEdit" :key="index"
                             :type="field.type" :value="tableItems[data.index][field.key]"
                             @input="(value) => inputHandler(value, data.index, field.key)"></b-form-datepicker>
          <b-form-select v-else-if="field.type === 'select' && tableItems[data.index].isEdit" :key="index"
                         :value="tableItems[data.index][field.key]"
                         @input="(value) => inputHandler(value, data.index, field.key)"
                         :options="field.options"></b-form-select>

          <div :key="index" v-else-if="field.type === 'edit'">
            <b-button class="border-0" size="sm" variant="outline-info" @click="editRowHandler(data)">
              <b-icon v-if="!tableItems[data.index].isEdit" icon="pencil" font-scale="1"></b-icon>
              <b-icon v-else icon="check" font-scale="1"></b-icon>
            </b-button>
            <b-button class="border-0" size="sm" variant="outline-danger"
                      @click="openDialog = true; currentIndex = data.index">
              <b-icon icon="x" font-scale="1.5"></b-icon>
            </b-button>
          </div>
          <b-form-input v-else-if="field.type && tableItems[data.index].isEdit" :key="index" :type="field.type"
                        :value="tableItems[data.index][field.key]"
                        @blur="(e) => inputHandler(e.target.value, data.index, field.key)"></b-form-input>
          <span :key="index" v-else>{{ data.value }}</span>
        </template>
      </b-table>
    </div>

  </div>
</template>

<script>
import {eventBus} from "@/main";

export default {
  name: "RouteTable",
  props: {
    value: Array,
    fields: Array
  },
  data() {
    return {
      isShowSearch: false,
      searchFilter: null,
      openDialog: false,
      currentIndex: -1,
      tableItems: this.makeTableItems(this.$store.state.routes.route)
    }
  },
  // computed: {
  //   tableItems(){
  //     return this.value.map(item => ({...item, isEdit: false}))
  //   }
  // },
  mounted() {
    eventBus.$on('route-update-from-map', route => {
      this.tableItems = this.makeTableItems(route);
    })
  },
  methods: {
    commitChanges(){
      this.$store.commit('routes/setRoutePoints', this.tableItems.route);
      eventBus.$emit('route-changed-from-table', this.tableItems);
    },
    makeTableItems(items){
      return items.map(item => ({...item, isEdit: false}));
    },
    onRowSelected(items) {
      console.log(items)
      eventBus.$emit('wp-selected', items[0])
    },
    editRowHandler(data) {
      this.tableItems[data.index].isEdit = !this.tableItems[data.index].isEdit;
      if (this.tableItems[data.index].isEdit === false) this.commitChanges();
    },
    inputHandler(value, index, key) {
      this.tableItems[index][key] = value;
      this.$set(this.tableItems, index, this.tableItems[index]);
      this.$emit("input", this.tableItems);
    },
    addRowHandler() {
      const newRow = this.fields.reduce((a, c) => ({...a, [c.key]: null}), {})
      newRow.isEdit = true;
      this.tableItems.unshift(newRow);
      this.$emit('input', this.tableItems);
    },
    removeRowHandler() {
      this.tableItems = this.tableItems.filter((item, i) => i !== this.currentIndex);
      this.$emit('input', this.tableItems);
      this.commitChanges();
    }
  }
}
</script>

<style scoped>

</style>