export function priceGP(price){
    let primarPrice = Number(price);
    let GPPrice = primarPrice;

    // let GPPrice = 0;
    //
    // if (primarPrice == 0) {
    //     GPPrice = 0;
    // } else if (primarPrice < 10) {
    //     GPPrice = 10;
    // }
    //
    // if (primarPrice >= 10) {
    //     GPPrice = primarPrice + primarPrice * 0.3;
    // }

    return GPPrice.toFixed(2);
}