<template>
  <div>

    <b-card style="min-width: 18rem;" class="mt-2 small-black" bg-variant="light" text-variant="black" header="DEVICE">
      <div class="small-black">
        <span class="small-gray-card">Device ID:</span> {{ $store.state.current.profile.device_id }}<br>

        <div v-if="selected.license.products">
          <div v-if="state_todo.email">
            <span class="small-gray">Email: </span>
            <span class="small-black">{{ state_todo.email }}</span>
            <br>
          </div>
        </div>


        <span class="small-gray-card">App:</span> {{ $store.state.current.profile.app_name }} {{ $store.state.current.profile.app_version }}<br>

<!--        <span v-if="$store.state.current.profile.appState && $store.state.current.profile.appState.name" class="small-gray-card">Ship:</span> {{ $store.state.current.profile.appState.name }}<br>-->
        <span v-if="$store.state.manage.appstate && $store.state.manage.appstate.shipInfo.name" class="small-gray-card">Ship:</span> {{ $store.state.manage.appstate.shipInfo.name }}

<!--        <span class="small-gray-card">Ver:</span> {{ $store.state.current.profile.app_version }}<br>-->
      </div>
    </b-card>


<!--    <div v-if="selected.license.products">-->
<!--      <span class="small-gray">Active product (test): </span>-->
<!--      <span class="small-black">{{ appstate.programInfo.activeProductId }}</span>-->
<!--      <br>-->
<!--    </div>-->

<!--    <div v-if="selected.license.products">-->
<!--      <span class="small-gray">License id (test): </span>-->
<!--      <span class="small-black">{{ selected.license.products.at(0).id }}</span>-->
<!--      <br>-->
<!--    </div>-->
<!--    <hr>-->

<!--    <span class="small-cyan">{{ selected.license.title }}</span><br>-->

<!--    <b-card style="min-width: 18rem;" class="mt-2 small-black" bg-variant="light" text-variant="black" :header=selected.license.title>-->

<!--    <div v-if="selected.license.products">-->
<!--      <span class="small-gray">Publisher: </span>-->
<!--      <span class="small-black">{{ selected.license.publisher }}</span>-->
<!--    </div>-->

<!--    <div-->
<!--        v-if="selected.license.products && selected.license.products.at(0).license && selected.license.products.at(0).license.deviceNum">-->
<!--      <span class="small-gray">Devices: </span>-->
<!--      <span class="small-black">{{ selected.license.products.at(0).license.deviceNum }}</span>-->
<!--      <br>-->
<!--    </div>-->

<!--    <div v-if="selected.license.products">-->
<!--      <div v-if="state_todo.email">-->
<!--        <span class="small-gray">Email: </span>-->
<!--        <span class="small-black">{{ state_todo.email }}</span>-->
<!--        <br>-->
<!--      </div>-->
<!--    </div>-->

<!--    <div v-if="selected.license.products">-->
<!--      <span class="small-gray">Type: </span>-->
<!--      <span v-if="selected.license.products.at(0).licenseType === 'com'" class="small-black">Commercial</span>-->
<!--      <span v-if="selected.license.products.at(0).licenseType === 'corp'" class="small-black">Corporative</span>-->
<!--      <span v-if="selected.license.products.at(0).licenseType === 'free'" class="small-black">Free</span>-->
<!--    </div>-->

<!--    <div-->
<!--        v-if="selected.license.products && selected.license.products.at(0).license && selected.license.products.at(0).license.isValid">-->
<!--      <span class="small-gray">Valid: </span>-->
<!--      <span class="smt11">yes</span>-->
<!--      <br>-->
<!--    </div>-->

<!--    </b-card>-->

  </div>
</template>

<script>
import {eventBus} from "@/main";

export default {
  data() {
    return {
      selected: {
        license: "",
      },
    }
  },
  props: [],
  methods: {},
  created() {
    eventBus.$on('licensing_update', data => {
      this.selected.license = data;
    });

  },
  computed: {
    isAdmin(){
      return this.$store.state.auth.user.role === 'admin';
    },
    appstate() {
      return this.$store.state.manage.appstate;
    },
    state_todo() {
      return this.$store.state.charts.profile;
    }
  }

}
</script>

<style scoped>

</style>