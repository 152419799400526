const initialState = {
    route: {
        url: '',
        update_url: '',
        update_data: {},
        response: {},
    },
}

export const debug = {
    namespaced: true,
    state: initialState,
    actions: {},
    mutations: {
        setRouteURL(state, url) {
            state.route.url = url;
        },
        setRouteUpdateURL(state, payload) {
            state.route.update_url = payload.url;
            state.route.update_data = payload.data;
        },
        setRouteResponce(state, response) {
            state.route.response = response;
        },
    }
}