<template>
  <div class="row">
    <div class="col-12">
      <div class="row">
        <div class="col-6">
        <div class="row">
        <div class="col-7 border-right" style="height: 800px; overflow-y: scroll;">
          <b-badge variant="light">INSTALLATIONS</b-badge>
          <br><br>

          <installations :installationsList="instList"
                         :appsList="optionsAppType"
                         :map=map
                         selectedAppTypeInit='mkart'
                         parent='manage'
                         @app-type-selected="this.displayShips"></installations>

        </div>

        <div class="col-5 border-right" style="height: 800px; overflow-y: scroll;">
          <b-badge variant="light">DATA</b-badge>
          <br/><br/>

          <b-form-select
              v-model="selectedFileType"
              v-on:change="filesTypeSelected"
              :options="filesTypes"
              class="mb-0"
              size="sm"
              value-field="item"
              text-field="name"
          ></b-form-select>
          <br><br>

          <div v-show="isAppStateView()">
            <app-state :map=map :geocontent_list="geocontentList"></app-state>
          </div>

          <div v-show="!isAppStateView()">
            <all-files :files_list="filesList"></all-files>
          </div>

        </div>
        </div>
        </div>
        <div class="col-6 ">
          <app-state-map :map=map></app-state-map>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import AllFiles from "@/views/manage/files/AllFiles";
import Installations from "@/views/manage/Installations";
import AppState from "@/views/devices/AppState";
import AppStateMap from "@/views/devices/AppStateMap";
import Statistic from "@/views/manage/files/Statistic";
import Map from "@/components/Map";
import {file} from 'paths.macro';
import {eventBus} from "@/main";
import * as utils from "@/utils/utils";
import {NavLeaf} from "@/my-leaf/nav-leaf";
import * as fields from "@/utils/fields";
import {IS_NP} from "@/utils/build-config";

export default {
  components: {Installations, AppState, AppStateMap, AllFiles, Statistic, MyMap: Map},
  data() {
    return {
      map: new NavLeaf(),

      optionsAppType:
          (!IS_NP ?
              [
                {item: 'mkart', name: 'mKart'},
                {item: 'np', name: 'Njord Pilot'},
                {item: 'npm', name: 'Njord Pilot Mobile'},
                {item: 'nv', name: 'Vega'},
                {item: 'm-Connect', name: 'm-Connect'},
                {item: 'ECDIS', name: 'ECDIS'},
              ] : [
                {item: 'np', name: 'Njord Pilot'},
                {item: 'npm', name: 'Njord Pilot Mobile'},
              ]),
      selected_item_id: -1,
      selectedFileType: 'appstate',
      filesTypes: [
        {item: 'appstate', name: 'AppState'},
        {item: 'poi', name: 'Bookmarks'},
        {item: 'logbook', name: 'Logbooks'},
        {item: 'navalcatalog', name: 'Naval Catalog'},
        {item: 'nmealog', name: 'NMEA Logs'},
        {item: 'profile', name: 'Profiles'},
        {item: 'routes', name: 'Routes'},
        {item: 'settings', name: 'Settings'},
        {item: 'shipslibrary', name: 'Ships Library'},
        {item: 'userlayers', name: 'User Layers'},
        {item: 'statisticsdata', name: 'Statistics', notEnabled: false},
      ],
    }
  },
  watch : {
    geocontentList(){
      if (this.$store.state.manage.selected_geocontent){
        localStorage.setItem('selected-geocontent', JSON.stringify(this.$store.state.manage.selected_geocontent));
      }
    }
  },
  computed: {
    instList() {
      return this.$store.state.manage.installations.at(this.getCurrentRouter());
    },
    filesList() {
      return this.$store.state.manage.files_list[this.selected_item_id];
    },
    geocontentList() {
      this.$store.dispatch('changeSelectedGeocontent', this.$store.state.manage.geocontent_list[this.selected_item_id]).then(() => {
      })

      return this.$store.state.manage.geocontent_list[this.selected_item_id];
    }
  },
  created() {
    eventBus.$on('reset_files_filter', data => {
      this.selectedFileType = 'appstate';
    });

    eventBus.$on('item_selected', data => {
      this.selected_item_id = data.aindex;
    });
  },
  mounted() {
    console.log(file, "mounted");
    this.$store.dispatch('getInstallations').then(() => {
      eventBus.$emit('installationsReceived');
    });
    this.$store.dispatch('getAllProducts').then(() => {
    });

    // if (!this.$store.state.current.s63_primar.info_loaded) {
    //   this.$store.dispatch('getENCs');
    // }

  },
  methods: {
    isAppStateView() {
      return fields.selectedFilesView(this) === 'appstate';
    },
    displayShips(items) {
      this.map.clearAppStates();
      let filteredInstList = items;
      if (filteredInstList) {
        filteredInstList.forEach((inst) => {
          if (inst && inst.appState && inst.appState.positionInfo) {
            let lat = inst.appState.positionInfo.latDegree;
            let lon = inst.appState.positionInfo.lonDegree;
            let cog = inst.appState.positionInfo.cogDegree;
            let id = inst.deviceId;
            let days = inst.appState.positionInfo.datetimeUtc;
            let gpsDate = inst.appState.positionInfo.datetimeUtc;
            let appStateDate = inst.appState.positionInfo.timestampUtc;
            let aindex = inst.aindex;
            let name = inst.appState.shipInfo.name;
            let route = inst.appState.activeRoute;
            let sog = utils.roundNumber(inst.appState.positionInfo.sogKnot);
            let email = inst.appState.email;
            let isActive = inst.appState.isActive;
            let routeJson = inst.appState && inst.appState.activeRouteJson ? JSON.parse(inst.appState.activeRouteJson) : [];
            this.map.showAppState(aindex, lat, lon, cog, id, isActive, name, route, sog, email, gpsDate, appStateDate, routeJson);
          }
        })
      }
    },
    itemsByAppFilter() {
      let filter = this.$store.state.manage.apps_filter[this.getCurrentRouter()];
      let filteredList = utils.filterByAppName(this.instList, filter);

      return filteredList;
    },
    filesTypeSelected(option) {
      console.log(file, "filesTypeSelected: " + option);
      this.$store.dispatch('changeFilesFilter', option).then(() => {
        this.$root.$emit('bv::refresh::table', 'files-table')
      })
    },
    getCurrentRouter() {
      return utils.currentRouterId();
    }
  },
}
</script>

<style>
@import '@/assets/styles/styles.css';
</style>