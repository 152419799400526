<template>
  <div>
    <b-badge variant="light">DETAILS</b-badge>
    <br><br>
    <div>
      <my-map :map=map :height="mapHeight"></my-map>
    </div>
  </div>
</template>

<script>
import Map from "@/components/Map";
import {eventBus} from "@/main";

export default {
  props: ["map"],
  components: {MyMap: Map},
  data() {
    return {
      mapHeight: window.innerHeight / 1.2,
      selected: {
        device_id: "",
        statistic: [],
      },
    }
  },
  mounted() {
  },
  created() {
    eventBus.$on('show_details', data => {

      this.selected.device_id = data.device_id;
      this.selected.device_platform = data.device_platform;
      this.selected.device_email = data.device_email;
      this.selected.device_license = data.device_license;

      this.selected.app_name = data.app_name;
      this.selected.app_ver = data.app_ver;
      this.selected.app_reqkey = data.app_reqkey;
      this.selected.app_route = data.app_route;
      this.selected.app_active = data.app_active;

      this.selected.ship_name = data.ship_name;
      this.selected.ship_mmsi = data.ship_mmsi;
      this.selected.ship_imo = data.ship_imo;
      this.selected.ship_length = data.ship_length;
      this.selected.ship_width = data.ship_width;
      this.selected.ship_height = data.ship_height;
      this.selected.ship_draught = data.ship_draught;
      this.selected.ship_sensore = data.ship_sensore;

      this.selected.pos_hdop = data.pos_hdop;
      this.selected.pos_lat = data.pos_lat;
      this.selected.pos_lon = data.pos_lon;
      this.selected.pos_cog = data.pos_cog;
      this.selected.pos_sog = data.pos_sog;
      this.selected.pos_hdg = data.pos_hdg;
      this.selected.pos_speed = data.pos_speed;
      this.selected.pos_mac = data.pos_mac;
      this.selected.pos_rot = data.pos_rot;
      this.selected.pos_date = data.pos_date;
      this.selected.pos_time = data.pos_time;
      this.selected.pos_tick = data.pos_tick;
      this.selected.pos_lost = data.pos_lost;
      this.selected.pos_satellites = data.pos_satellites
    })
  },
}
</script>
