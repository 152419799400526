<template>
  <div>
    <details v-if="!alt">
<!--    <details v-if="!alt" :open="open">-->
      <summary>
<!--        <span v-if="order.status != 'new'">Order {{ formatDate(order.date_purchased) }} {{ getPriceGP(order.price) }}$</span>-->

<!--        <span v-if="order.paymentMethod === 'stripe'" class="small-gray">Price:</span> {{ order.price }}€<br/>-->
<!--        <span v-if="order.paymentMethod !== 'stripe'" class="small-gray">Price:</span> {{ order.price }}₽<br/>-->

        <span v-if="order.status != 'new'">{{ formatDate(order.createdUtc) }} ({{ getPriceGP(order.price) }})<span class="small-gray">,
          <span :class="getStatus()">
            {{ translateStatus(order.status) }}
          </span>
        </span></span>
<!--        <span>-->
<!--          <span v-if="order.status != 'new'" class="small-gray"> {{ order.status }} </span>-->
          <span v-if="order.status === 'new'">
            {{ formatDate(order.createdUtc) }} ({{ getPriceGP(order.price) }})<span class="small-gray">,  {{ translateStatus(order.status) }} </span>
<!--          <b-button-->
<!--              size="sm"-->
<!--              @click="pay(order.orderId)"-->
<!--              variant="outline-success"-->
<!--          >Pay2 {{ getPriceGP(order.price) }}$</b-button><br/><br/>-->
          </span>


          <b-spinner v-if="order.status == 'processing'" small type="grow" variant="warning"></b-spinner>
<!--        </span>-->

      </summary>

      <div class="small-black">

        <div v-if="order.status === 'new' && order.paymentMethod !== 'agreement'" >
<!--          <b-button :disabled="runSpinner" size="sm" @click="changePaymentMethod(order.orderId, order.paymentMethod)" variant="outline-success">-->
<!--            <b-spinner v-if="runSpinner" small></b-spinner>-->
<!--            <span v-if="runSpinner"> changing...</span>-->
<!--            <span v-else>change payment method</span>-->
<!--          </b-button>&nbsp;-->
          <!--          <b-button size="sm" @click="pay(order.orderId)" variant="outline-warning">edit</b-button>&nbsp;-->
          <!--          <b-button size="sm" @click="pay(order.orderId)" variant="outline-danger">delete</b-button><br/><br/>-->


          <b-button size="sm" @click="pay(order.orderId)" variant="outline-success">pay</b-button>&nbsp;
<!-- TODO          <b-button v-if="isAdmin" size="sm" @click="approve(order.orderId)" variant="outline-warning">approve</b-button>&nbsp;-->
<!--          <b-button size="sm" @click="pay(order.orderId)" variant="outline-warning">edit</b-button>&nbsp;-->
<!--          <b-button size="sm" @click="pay(order.orderId)" variant="outline-danger">delete</b-button><br/><br/>-->
        </div>

<!--        <a href="#">pay</a>, <a href="#">edit</a>, <a href="#">delete</a><br>-->

        <span class="small-gray">Order ID:</span> {{ order.orderId }}<br />
        <span class="small-gray">Created:</span> {{ formatDateTime(order.createdUtc) }}<br />
<!--        <span class="small-gray">Price:</span> {{ getPriceGP(order.price) }}$<br />-->
        <span class="small-gray">Payment method:</span> {{ order.paymentMethod }}<br />
        <div v-if="order.paymentMethod !== 'trial'">
          <span class="small-gray">Price:</span> {{ getPriceGP(order.price) }}
          <span v-if="order.paymentMethod === 'stripe'">€</span><span v-if="order.paymentMethod !== 'stripe'">₽</span><br/>
        </div>

        <span class="small-gray">Period:</span> {{ order.items[0].monthCount }} months<br />

        <div v-if="order.deviceId">
          <span class="small-gray">Device ID:</span> <span class="small-black">{{ order.deviceId }}</span><br />
        </div>
        <div v-if="order.permit">
          <span class="small-gray">Permit:</span> <span class="small-black">{{ order.permit }}</span><br />
        </div>

        <div v-if="order.paymentMethod === 'trial'">
          <span class="small-gray">Trial:</span> <span class="smt11">yes</span><br />
        </div>
        <div v-if="order.paymentMethod === 'agreement'">
          <span class="small-gray">Agreement:</span> <span class="smt11">yes</span><br />
        </div>

<!--        </div>-->

        <details>
          <summary class="small-gray">
            <span class="small-gray">Cells: ...</span> <br />
          </summary>
          <ul>
            <li :key="index" v-for="(item, index) in order.items">
              <a href="#" @click="showCellOnMap(item.id, order.items)"><span class="small-cyan">{{ item.id }}</span></a>
            </li>
          </ul>
        </details>
        <hr>


      </div>

    </details>
    <b-card v-else-if=alt>

      {{ formatDate(order.createdUtc) }} ({{ getPriceGP(order.price) }}), <span :class="getStatus()"> {{ order.status }} </span>

<!--      <span v-if="order.status != 'new'">Order {{ formatDate(order.date_purchased) }} {{-->
<!--          getPriceGP(order.price)-->
<!--        }}$-->
<!--      </span>-->
      <span>
          <span v-if="order.status != 'new'" class="small-gray"> {{ order.status }} </span>
          <span v-if="order.status === 'new'">
          <b-button
              size="sm"
              @click="pay(order.orderId)"
              variant="outline-success"
          >Pay1 {{ getPriceGP(order.price) }}$</b-button><br/><br/>
          </span>


          <b-spinner v-if="order.status == 'processing'" small type="grow" variant="warning"></b-spinner>
        </span>

      <div class="small-black">

        <span class="small-gray">Created:</span> {{
          formatDateTime(order.createdUtc)
        }}<br/>
        <span v-if="order.paymentMethod === 'stripe'" class="small-gray">Price:</span> {{ order.price }}€<br/>
        <span v-if="order.paymentMethod !== 'stripe'" class="small-gray">Price:</span> {{ order.price }}₽<br/>

        <span class="small-gray">Cells:</span> <br/>
        <ul>
          <li :key="index" v-for="(item, index) in order.items">
            {{ item.id }}
          </li>
        </ul>
      </div>
    </b-card>
  </div>
</template>

<script>
import * as utils from "@/utils/utils";
import * as fields from "@/utils/fields";
import myAxios from "@/services/myAxios";
import {priceGP} from "@/helpers/price";
import {payWithStripe} from "@/helpers/pay";
import {eventBus} from "@/main";
import {file} from "paths.macro";

export default {
  data() {
    return {
      file_zip: null,
      url: "",
      runSpinner: false,
    };
  },
  props: ["order", "index", "open", "alt", "map"],
  components: {},
  computed: {
    isAdmin() {
      return this.$store.state.auth.user.role === 'admin';
    },
    zipfilename() {
      if (this.order.files.length > 0) {
        let zipfile = this.order.files.find((o) => o.type === "s63 maps zip");
        if (zipfile) return zipfile.name;
      }
      return null;
    },
    charts() {
      return this.$store.state.charts.s63.items;
    },
    isReadyForConvert() {
      console.log(this.order.isPaid, this.order.permit, this.zipfilename);
      return (
        this.order.isPaid 
        && this.order.permit !== null
        && this.zipfile !== null
      );
    },
  },
  methods: {
    showCellOnMap(id, items){
      // eventBus.$emit('pane2cell', {id:id, items:items})
      myAxios.get('https://win1hetz.bgeo.fi:5005/run?id=14424&isIdFromGc=true').then(resp => {
        if (resp.status === 200) {
          const url = resp.data + "map/{z}/{x}/{y}";
          this.map.setSpecialTiles(url)
        }
        if (resp.status === 204) {
          console.log(" 204 error")
        }
      });
    },

    translateStatus(status) {
      let newStatus = "status";
      switch(String(status)) {
        case "new":
          newStatus = "new";
          break;
        case "payment_processing":
          newStatus = "payment processing";
          break;
        case "paid":
          newStatus = "paid";
          break;
        case "ready_to_publish":
          newStatus = "processing [1]";
          break;
        case "complete":
          newStatus = "complete";
          break;
        case "processing":
          newStatus = "processing [2]";
          break;
        case "awaiting_charts":
          newStatus = "processing [3]";
          break;
        case "adding_charts":
          newStatus = "processing [4]";
          break;
        case "error":
          newStatus = "error";
          break;
      }

      return newStatus;
    },

    getPriceGP(price){
      return priceGP(price);
    },
    getStatus() {
      let status = "";

      if (this.order.status === 'new') {
        status = 'smt11'
      }

      if (this.order.status === 'processing') {
        status = 'smt12'
      }

      if (this.order.status === 'error') {
        status = 'smt14'
      }

      if (this.order.status === 'complete') {
        status = 'smt11'
      }

      return status;
    },
    refresh() {
      this.file_zip = null;
      this.$store.dispatch('current/loadingOrders', true);
      this.$store.dispatch('current/addLoadingMarker', "orders");
      console.log("aaaaaaaaaaaa2")
      this.$store.dispatch("getS63OrdersList", {
        device_id: this.$store.state.current.profile.device_id,
        user_id: this.$store.state.current.profile.user_id,
      });
      this.$store.dispatch("current/pushLoadingMarker", "AAA|getS63Summary")
      this.$store.dispatch("getS63Summary", {
        device_id: this.$store.state.current.profile.device_id,
        user_id: this.$store.state.current.profile.user_id,
      }).then(() => {
        this.$store.dispatch('current/loadingOrders', false);
        this.$store.dispatch('current/removeLoadingMarker', "orders");
      });
    },

    formatDate(date) {
      return utils.formatDate(date).date;
    },
    formatDateTime(date) {
      return utils.formatDate(date).datetime;
    },
    changePaymentMethod(orderID, prevMethod) {
      console.log(orderID, prevMethod)

      this.runSpinner = true;
      let newMethod = prevMethod === 'yoomoney' ? 'stripe' : 'yoomoney';

      myAxios.post('/api/v1/orders/update/'+ orderID + '?paymentMathod=' + newMethod).then( resp => {
        console.log("changed")
        this.runSpinner = false;
      })


    },
    pay(orderId) {
      this.$router.push({name : 'loader'});
      return payWithStripe(orderId);
    },

    approve(orderId) {

      myAxios
          .get("/api/v1/admin/orders/publish/" + orderId, {
            headers: {
              "content-type": "application/json",
            },
          })
          .then((resp) => {
            console.log(resp)
            // window.open(resp.data);
            location.reload();

          })
          .catch((error) => {
              alert('something went wrong', file)
            })


    },

    submitFiles() {
      let orderId = this.$store.state.charts.s63.orders_list[0].orderId;

      // let zip = new FormData();
      // zip.append(this.file_zip.name, this.file_zip);
      myAxios
        .post(
          "/api/v1/orders/updatefile/" + this.order.orderId,
          this.file_zip,
          {
            headers: {
              Accept: "*/*",
              "Content-Type": "application/octet-stream",
            },
            params: {
              fileType: "s63 maps zip",
              fileName: this.file_zip ? this.file_zip.name : "",
              comment: this.url,
              userPermit: this.order.permit,
            },
          }
        )
        .then(() => {
          this.refresh();
        });
    },
  },
};
</script>

<style scoped>
</style>