<template>

  <div>

    <b-modal id="modal-share" title="Enter Device ID" v-model="isShowShareForm"
             @show="resetModal"
             @hidden="resetModal"
             @ok="handleOk">
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <b-form-group
            label="Device ID"
            label-for="name-input"
            invalid-feedback="Device ID is required"
            :state="shareState"
        >
          <b-form-input
              id="name-input"
              v-model="shareDeviceID"
              :state="shareState"
              required
          ></b-form-input>
        </b-form-group>
      </form>
    </b-modal>

<!--    <b-modal-->
<!--        id="modal-prevent-closing"-->
<!--        ref="modal"-->
<!--        title="Submit Your Name"-->
<!--        @show="resetModal"-->
<!--        @hidden="resetModal"-->
<!--        @ok="handleOk"-->
<!--    >-->
<!--      <form ref="form" @submit.stop.prevent="handleSubmit">-->
<!--        <b-form-group-->
<!--            label="Name"-->
<!--            label-for="name-input"-->
<!--            invalid-feedback="Name is required"-->
<!--            :state="nameState"-->
<!--        >-->
<!--          <b-form-input-->
<!--              id="name-input"-->
<!--              v-model="name"-->
<!--              :state="nameState"-->
<!--              required-->
<!--          ></b-form-input>-->
<!--        </b-form-group>-->
<!--      </form>-->
<!--    </b-modal>-->

    <b-container fluid>
    <br>

<!--    :current-page="currentPage"-->
<!--    :per-page="perPage"-->

    <b-table
        id="files-table"
        class="secondary"
        :items="visible_rows()"
        :fields="visible_fields"
        :filter="filter"
        :filter-included-fields="filterOn"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :busy="isBusy"
        :tbody-transition-props="transProps"
        primary-key="id"
        select-mode="single"
        show-empty
        responsive
        sort-icon-left
        small
        hover
        @row-selected="onRowSelected"
    >

      <template #table-busy>
        <div class="text-center my-2">
          <b-spinner class="align-middle"></b-spinner>
          <br>
          <strong>Loading...</strong>
        </div>
      </template>

      <template v-slot:cell(download)="{ item }">
        <b-button-toolbar size="sm">

          <b-button size="sm"  title="Load file" @click="loadItem(item)">
            <b-icon icon="cloud-download" aria-hidden="true"></b-icon>
          </b-button>
          <b-button v-if="item.type === 'settings'"size="sm" title="Upload file" @click="uploadItem(item)">
            <b-icon icon="cloud-upload" aria-hidden="true"></b-icon>
          </b-button>
          <b-button size="sm"  title="Share file" @click="shareItem(item)">
            <b-icon icon="share" aria-hidden="true"></b-icon>
          </b-button>

        </b-button-toolbar>
      </template>

      <template v-slot:cell()="data">
        <span v-b-tooltip.hover :title=data.value>{{ data.value}}</span>
      </template>

    </b-table>

<!--    <b-button size="sm">Download</b-button> <b-button size="sm">Share</b-button>-->

  </b-container>
  </div>
</template>

<script>
import {eventBus} from '@/main'
import Statistic from "@/views/manage/files/Statistic";
import {ActiveTab, AppType} from "@/utils/config";
import Map from "@/components/Map";
import {file} from 'paths.macro';
import store from "@/store";
import myAxios from "@/services/myAxios";
import {currentRouterId} from "@/utils/utils";

export default {
  components: {},
  data() {
    return {
      fileId: "",
      shareDeviceID: "",
      shareState: null,
      isShowShareForm: false,
      isBusy: false,
      transProps: {
        // Transition name
        name: 'flip-list'
      },
      selectedParent: {
        deviceid: "",
        statistic: [],
      },
      selected: [],
      fields: [
        {key: 'name', label: 'Name', sortable: true, sortDirection: 'desc', tdClass: 'small-black', thClass: 'small-gray', ownVisible: true, class: 'truncate_name'},
        {key: 'download', label: '', thClass: 'small-gray', ownVisible: true},
      ],
      // currentPage: 1,
      // perPage: 10,
      // pageOptions: [5, 10, 15, {value: 50, text: "Show a lot"}],
      sortBy: 'appstate_pos_time',
      sortDesc: true,
      sortDirection: 'desc',
      filter: null,
      filterOn: [],
    }
  },
  props: ["files_list"],
  watch: {
    '$store.state.manage.is_loading': function () {
      this.isBusy = this.$store.state.manage.is_loading;
    }

  },
  computed: {
    // hide email column for own devices
    visible_fields() {
      if (currentRouterId() === ActiveTab.DEVICES) {
        return this.fields.filter(field => field.ownVisible);
      } else {
        return this.fields;
      }
    },
  },
  methods: {
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity()
      this.shareState = valid
      return valid
    },
    resetModal() {
      this.shareDeviceID = ''
      this.shareState = null
    },
    handleOk(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return
      }

      const params = {
        fileId: this.fileId,
        sourceDeviceId: this.$store.state.current.profile.device_id,
        targetDeviceId: this.shareDeviceID,
      };

      console.log(params)

      myAxios.post('/api/v1/userfile/copy', params,
          {
            headers: {
              'content-type': 'application/json',
            }
          }
      )

      // Push the name to submitted names
      // this.submittedNames.push(this.name)
      // Hide the modal manually
      this.$nextTick(() => {
        this.$bvModal.hide('modal-share')
      })
    },

    visible_rows() {
      let filter = this.$store.state.manage.files_filter;
      console.log(file, "visible_rows " + filter);

      let items = null;
      if (this.files_list) {
        items = this.files_list.filter(function (e) {
          return e.type === filter;
        });
      }

      return items;
    },
    onRowSelected(items) {
      // this.selected = items
      // myAxios.get('api/v1/userfile/' + items.at(0).id, {responseType: 'blob'})
      //     .then((response) => {
      //       const url = window.URL.createObjectURL(new Blob([response.data]));
      //       const link = document.createElement('a');
      //       link.href = url;
      //       link.setAttribute('download', items.at(0).name); //or any other extension
      //       document.body.appendChild(link);
      //       link.click();
      //     });
    },
    shareItem(item) {
      this.fileId = item.id
      this.isShowShareForm = true;
    },
    loadItem(item) {
      console.log(item)

      myAxios.get('api/v1/userfile/' + item.id, {responseType: 'blob'})
          .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', item.name); //or any other extension
            document.body.appendChild(link);
            link.click();
          });
    },
    uploadItem(item) {
      console.log(item)

      var input = document.createElement('input');
      input.type = 'file';

      input.onchange = e => {

        // getting a hold of the file reference
        var file = e.target.files[0];

        // setting up the reader
        var reader = new FileReader();
        reader.readAsText(file,'UTF-8');

        // here we tell the reader what to do when it's done reading...
        reader.onload = readerEvent => {
          var content = readerEvent.target.result; // this is the content!
          console.log( content );
        }

      }

      input.click();


    }
  }
}
</script>

<style>
table#files-table .flip-list-move {
  transition: transform 1s;
}

.truncate_name {
  max-width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

</style>
