import L from "leaflet";
import axios from "axios";
import {NavLeaf} from "./nav-leaf";
import store from '../store'
import {eventBus} from "@/main";
import "./extensions/Leaflet.PolylineMeasure"
import "./extensions/Leaflet.PolylineMeasure.css"
import "./extensions/leaflet-corridor"
import "./extensions/direct"
import iconByDays from "@/assets/ship_1tx_green.png";
import icon from "@/assets/marker-danger.png";
import {destVincenty, toDeg} from "@/my-leaf/extensions/direct";
import myAxios from "@/services/myAxios";
import './route-styles.css'

// function meters2degrees(m, startLat){
//     let lat = m/111111
//     let lon = m/(111111 * Math.cos(startLat*Math.PI/180))
//     return {lat: lat, lon: lon};
// }

let progressCounter = 0;
let prevProgress = -1;
let pointsCount = 0;
let prevDraught = -1;


export class RouteLeaf extends NavLeaf {
    constructor() {
        super();

    }

    async getRoute(routeId) {


        return new Promise((resolve) => {
            // get route progress and state
            myAxios.get('api/v1/route/' + routeId)
                .then(resp => {
                    console.log(resp)

                    store.commit("debug/setRouteResponce", resp)

                    console.log(": ", progressCounter, resp.data.progress, prevProgress)

                    if (prevProgress !== resp.data.progress) {
                        prevProgress = resp.data.progress;
                        progressCounter = 0;
                    } else {
                        progressCounter++;
                    }
                    store.commit("routes/setProgress", resp.data.progress)


                    if (resp.data.errorCode !== 0 || progressCounter > 50) {
                        resolve(resp.data);
                    } else {
                        if (resp.data.isSuccess) {
                            resolve(resp.data);
                        } else {
                            setTimeout(() => {
                                console.log("route calculation...")
                                resolve(this.getRoute(routeId))
                            }, 700);
                        }
                    }
                })
        })
    }

    async init(el) {
        super.init(el);
        this.corridorLayer = L.layerGroup().addTo(this.map);
        this.warningMarkers = L.layerGroup().addTo(this.markerLayer);
        this.dangerMarkers = L.layerGroup().addTo(this.markerLayer);
        this.setTileLayerWithDraught()
        this.clearRoute();
        this.add_pMeasure(true);
        const a = document.getElementById('polyline-measure-control')
        a.hidden = false;
        console.log(a)
        let corridorPane = this.map.createPane('corridor');
        corridorPane.style.zIndex = 200;

        eventBus.$on('change-nogo', () => {

            if (prevDraught !== document.getElementById('sb-inline-draught').defaultValue) {
                console.log("new NO GO")
                prevDraught = document.getElementById('sb-inline-draught').defaultValue;
                this.setTileLayerWithDraught();
            }
        })
        eventBus.$on('wp-selected', wp => {
            this.map.flyTo([wp.lat, wp.lon], 15)
        })
        eventBus.$on('route-changed-from-table', items => {
            this.pMeasure._toggleMeasure();
            if (this.pMeasure._layerPaint) {
                this.pMeasure._layerPaint.clearLayers();
            }
            this.pMeasure._toggleMeasure();
            // this.pMeasure._toggleMeasure();
            // start line with first point of each polyline
            this.pMeasure._startLine(items[0]);
            // add subsequent points:
            items.forEach((point, ind) => {
                const latLng = L.latLng(point);
                this.pMeasure._mouseMove({latLng});
                this.pMeasure._currentLine.addPoint(latLng);
                // on last point,
                if (ind === items.length - 1) {
                    // this.pMeasure._finishPolylinePath();
                    // this.pMeasure._toggleMeasure();
                    this.pMeasure._onKeyDown({keyCode: 27})
                }
            });
            if (items.length > 2) this.updateRoute(items);
            else if (items.length == 2) eventBus.$emit('change-draught', items);
        })
        eventBus.$on('change-draught', (pts) => {
            this.setTileLayerWithDraught();
            const currentLine = this.pMeasure._currentLine ? this.pMeasure._currentLine : this.pMeasure._arrPolylines[0];
            console.log(this.pMeasure)
            this.clearRouteDrawings();
            if (currentLine.circleCoords.length > 1) {
                const start = currentLine.circleCoords[0];
                const finish = currentLine.circleCoords[currentLine.circleCoords.length - 1];
                const draught = document.getElementById('sb-inline-draught').defaultValue;
                const height = document.getElementById('sb-inline-height').defaultValue;
                const radius = document.getElementById('sb-inline-radius').defaultValue;
                const xte_l = document.getElementById('sb-inline-xte-l').defaultValue;
                const xte_r = document.getElementById('sb-inline-xte-r').defaultValue;

                console.log(draught)
                console.log(start, finish)
                this.pMeasure._toggleMeasure();
                if (this.pMeasure._layerPaint) {
                    this.pMeasure._layerPaint.clearLayers();
                }
                // this.map.spin(true);
                a.hidden = true

                // get id for route calculation
                let url = 'https://nroutes.bgeo.fi:6003/route?startLat=' + start.lat +
                    '&startLon=' + start.lng +
                    '&finishLat=' + finish.lat +
                    '&finishLon=' + finish.lng +
                    '&depth=' + draught +
                    '&height=' + height +
                    '&radius=' + radius +
                    '&xteLeft=' + xte_l +
                    '&xteRight=' + xte_r;

                store.commit("routes/resetStorage")
                eventBus.$emit('route-update-from-map', []);
                store.commit("debug/setRouteURL", url)
                myAxios.post('/api/v1/route', {
                    useAutoRoute: true,
                    points: pts ?
                        [
                            {
                                lat: pts[0].lat,
                                lon: pts[0].lon,
                                radius: pts[0].radius,
                                xteLeft: pts[0].xteLeft,
                                xteRight: pts[0].xteRight
                            },
                            {
                                lat: pts[1].lat,
                                lon: pts[1].lon,
                                radius: pts[1].radius,
                                xteLeft: pts[1].xteLeft,
                                xteRight: pts[1].xteRight
                            }
                        ]
                        :
                        [
                        {
                            lat: start.lat,
                            lon: start.lng,
                            radius: radius,
                            xteLeft: xte_l,
                            xteRight: xte_r
                        },
                        {
                            lat: finish.lat,
                            lon: finish.lng,
                            radius: radius,
                            xteLeft: xte_l,
                            xteRight: xte_r
                        }
                    ],
                    depth: draught,
                    height: height,
                    defaultXteLeft: xte_l,
                    defaultXteRight: xte_r,
                    defaultRadius: radius,
                    waitSeconds: 0
                })
                    .then(resp => {
                        console.log(resp)
                        if (resp.data.errorCode === 0 && resp.data.id) {
                            store.commit("routes/setCalculatingStatus", true)
                            // get route calculation progress and result
                            progressCounter = 0;
                            prevProgress = -1;
                            this.getRoute(resp.data.id).then(routeResponce => {
                                console.log(routeResponce.isSuccess)
                                if (routeResponce.isSuccess) {
                                    store.commit("routes/setCalculatingStatus", false)

                                    const pts = routeResponce.routePoints.slice(1, routeResponce.routePoints.length - 1);
                                    store.commit("routes/setRoute", {
                                        route: [],
                                        length: '',
                                        dangers: [],})
                                    store.commit("routes/setRoute", {
                                        route: pts,
                                        length: routeResponce.routeLength,
                                        dangers: routeResponce.dangerObjects,
                                    })
                                    eventBus.$emit('route-update-from-map', pts);


                                    console.log(pts)

                                    this.pMeasure._toggleMeasure();
                                    // this.pMeasure._toggleMeasure();
                                    // start line with first point of each polyline
                                    this.pMeasure._startLine(pts[0]);
                                    // add subsequent points:
                                    pts.forEach((point, ind) => {
                                        const latLng = L.latLng(point);
                                        this.pMeasure._mouseMove({latLng});
                                        this.pMeasure._currentLine.addPoint(latLng);
                                        // on last point,
                                        if (ind === pts.length - 1) {
                                            // this.pMeasure._finishPolylinePath();
                                            // this.pMeasure._toggleMeasure();
                                            this.pMeasure._onKeyDown({keyCode: 27})
                                        }
                                    });
                                    // this.drawCorridors(pts, xte_l, xte_r);
                                    // routeResponce.dangerObjects.forEach(obj => {
                                    //     this.setDangerMarkers(obj.points, obj.isDanger ? require("../assets/marker-danger.png") : require("../assets/marker-warning.png"));
                                    // })
                                    this.updateRoute(this.pMeasure._currentLine);
                                } else {
                                    store.commit("routes/setCalculatingStatus", false)

                                    progressCounter = 0;
                                    prevProgress = -1;
                                    pointsCount = 0;

                                    if (routeResponce.errorCode === 4) {
                                        alert('Error: (' + routeResponce.errorCode + ') Finish point in a dry land');
                                    } else {
                                        alert('Error: (' + routeResponce.errorCode + ') Cannot compute route with these coordinates');
                                    }
                                }
                                this.map.spin(false)
                                a.hidden = false
                            })

                        } else {
                            store.commit("routes/setCalculatingStatus", false)
                            alert('Error: (' + resp.data.errorCode + ') Cannot compute route');
                        }
                    })
            }
        })
        this.map.on('polylinemeasure:toggle', () => {
            this.clearRouteDrawings();
            store.commit("routes/setRoute", {
                route: [],
                length: '',
                dangers: [],})
            eventBus.$emit('route-update-from-map', []);
        })
        this.map.on('polylinemeasure:clear', () => {
            this.clearRouteDrawings();
            pointsCount = 0;
            store.commit("routes/setRoute", {
                route: [],
                length: '',
                dangers: [],})
            eventBus.$emit('route-update-from-map', []);
        })

        this.map.on('polylinemeasure:change', currentLine => {
            if (currentLine.circleCoords.length > 2) this.updateRoute();
            else if (currentLine.circleCoords.length == 2) eventBus.$emit('change-draught');

            currentLine.circleMarkers.forEach(circle => {
                circle.unbindContextMenu();
            circle.bindContextMenu({
                contextmenuItems : [
                    {
                        text: "<input type=number>"
                    }
                ]
            });

            })
            })
        this.map.on('zoomend', () => {
            const zoom = this.map.getZoom();
            this.dangerMarkers.remove();
            this.warningMarkers.remove();
            if (zoom > 14) {
                console.log('zoom' ,zoom)
                this.warningMarkers.addTo(this.markerLayer);
                this.dangerMarkers.addTo(this.markerLayer);
            } else this.dangerMarkers.addTo(this.markerLayer);
        })
    }
    updateRoute(pts) {

        // const start = currentLine.circleCoords[0];
        // const finish = currentLine.circleCoords[currentLine.circleCoords.length - 1];
        const draught = document.getElementById('sb-inline-draught').defaultValue;
        const height = document.getElementById('sb-inline-height').defaultValue;
        const radius = document.getElementById('sb-inline-radius').defaultValue;
        const xte_l = document.getElementById('sb-inline-xte-l').defaultValue;
        const xte_r = document.getElementById('sb-inline-xte-r').defaultValue;
        let points = []

        if (!pts) {
            const currentLine = this.pMeasure._currentLine ? this.pMeasure._currentLine : this.pMeasure._arrPolylines[0];

            currentLine.circleCoords.forEach(pt => {
                points.push({
                    lat: pt.lat, lon: pt.lng, radius: radius,
                    xteLeft: xte_l,
                    xteRight: xte_r
                })
            })
        }
        else {
            pts.forEach(pt => {
                points.push({
                    lat: pt.lat, lon: pt.lon, radius: pt.radius,
                    xteLeft: pt.xteLeft,
                    xteRight: pt.xteRight
                })
            })
        }


        // console.log(currentLine.polylinePath)
        // console.log(points)

        store.commit("debug/setRouteUpdateURL", {url: 'https://nroutes.bgeo.fi:6003/updateroute', data: {
                points: points,
                depth: draught,
                height: height,
                radius: radius,
                xteLeft: xte_l,
                xteRight: xte_r
            }});

        this.clearRouteDrawings();
        // const pathPoints = currentLine.polylinePath._latlngs;
        // // console.log(pathPoints)
        // const dashPoints = this.dashPolyline(pathPoints);
        // this.drawCorridors(dashPoints, xte_l, xte_r, "green");
        // this.drawCorridors(pathPoints, xte_l, xte_r);
        eventBus.$emit('route-update-from-map', points);
        myAxios.post('/api/v1/route',
            {
                points: points,
                depth: draught,
                height: height,
                defaultXteLeft: xte_l,
                defaultXteRight: xte_r,
                defaultRadius: radius,
                useAutoRoute: false,
            }).then(resp => {


            store.commit("debug/setRouteResponce", resp)

            if (resp.data.isSuccess){
                const pts = resp.data.routePoints;
                const ptsInfos = resp.data.routePointInfos;
                let newpts= [];
                for (let i=0; i < pts.length;i++){
                    const pt = pts[i]
                    const pti = ptsInfos[i]
                    newpts.push({lat: pt.lat, lon: pt.lon, course: pti.course});
                }
                store.commit("routes/setRoute", {
                    route: [],
                    length: '',
                    dangers: [],})
                store.commit("routes/setRoute", {
                    route: pts,
                    length: resp.data.routeLength,
                    dangers: resp.data.dangerObjects,
                })

                resp.data.dangerObjects.forEach(obj => {
                    this.setDangerMarkers(obj.points, obj.isDanger ? "danger" : "warning")
                })
                const ptsDashLeft = this.dashPolyline(newpts, 'left', xte_l/2);
                const ptsDashRight = this.dashPolyline(newpts, "right", xte_r/2);
                this.drawCorridors(ptsDashLeft, xte_l, "green");
                this.drawCorridors(ptsDashRight, xte_r, "orange");
            }
        })
    }
    setTileLayerWithDraught(){
        if (this.navalLayer) this.navalLayer.remove();
        const draught = document.getElementById('sb-inline-draught').defaultValue;

        const url = "https://nogotiles.bgeo.fi:6007/nogo/{z}/{x}/{y}?depth=" + draught;
        console.log(url);
        this.navalLayer = L.tileLayer(url, {
            minZoom: 4,
            maxZoom: 17,
        }).addTo(this.map)
    }
    drawCorridors(route, width, color='orange'){

        let coords = [];
        // const width = Number(xteR) + Number(xteL);
        console.log(width)
        const options = {
            corridor: width, // meters
            // className: 'route-corridor'
            opacity: 0.3,
            lineCap: "butt",
            fill: false,
            pane: "corridor",
            interactive: false,
            color: color,
            noClip: true,
            lineJoin: "bevel"
        };
        route.forEach(pt => {
            if (pt){
                // console.log(pt)
                coords.push(L.latLng(pt.lat, pt.lon ? pt.lon : pt.lng))
            }
        })
        this.corridor = L.corridor(coords, options).addTo(this.corridorLayer);
        // console.log(this.corridor)
    }
    setDangerMarkers(points, type){
        // console.log(iconPath)
        //let icon = type == "danger" ? require("../assets/marker-danger.png") : require("../assets/marker-warning.png");

       let icon = type == "danger" ? "<div style='font-size: 20px'>&#128315;</div>" : "<div class='yellow'>&#128315;</div>"
       // let icon = type == "danger" ? "<div style='color: #e5d60f'>&#128315;</div>" : "<img src='./marker-warning.png'/>"
        let markerDanger = L.divIcon({
            className: '',
            iconSize: [20, 20],
            iconAnchor: [10, 10],
            html : icon   // best results if iconSize = font-size = line-height and iconAnchor font-size/2 .both values needed to position symbol in center of L.divIcon for all font-sizes.
        });

        points.forEach(pt => {
            let marker = L.marker([pt.lat, pt.lon], {icon: markerDanger, interactive: false});
            marker.addTo(type == 'danger' ? this.dangerMarkers : this.warningMarkers);
        })
    }
    clearRouteDrawings(){
        this.corridorLayer.clearLayers();
        this.dangerMarkers.clearLayers();
        this.warningMarkers.clearLayers();
    }
    dashPolyline(origPoints, dir='right', dist){
        let newPoints = []
        const bearing = dir == "right" ? 90 : -90;
        for (let i=0;i<origPoints.length;i++){
            const pt = origPoints[i]
            let courseDeg
            if (i == origPoints.length - 1) courseDeg = toDeg(pt.course);
            else courseDeg = toDeg(origPoints[i+1].course)

            newPoints.push(destVincenty(pt.lat, pt.lon ? pt.lon : pt.lng, bearing + courseDeg, dist))
        }
        console.log(origPoints, newPoints);
        return newPoints;
    }
}
