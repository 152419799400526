import myAxios from "@/services/myAxios";

const sd = JSON.parse(localStorage.getItem('selected-device'));
const initialState = {
    devices: [
    ],
    selectedDeviceId: sd ? sd.id : null,
    selectedDevice: sd ? sd.device : null,
    location: null,
    files: {}
}

export const devices = {
    namespaced: true,
    state: initialState,
    getters: {
        files(state) {
            return (id, fileType) => {
                return state.files[id].filter(file => file.type === fileType);
            }
        }
    },
    actions: {
        selectDevice({dispatch, commit}, id) {
            return new Promise(resolve => {
                commit('setSelectedDeviceId', id);
                for (let i = 0; i < this.state.devices.devices.length; i++) {
                    if (this.state.devices.devices[i].deviceId == id) {
                        commit('setSelectedDevice', this.state.devices.devices[i]);
                        localStorage.removeItem('selected-device');
                        localStorage.setItem('selected-device', JSON.stringify({
                            device: this.state.devices.devices[i],
                            id: id
                        }));
                        break;
                    }
                }
                resolve();
            })
        },
        getDevices({commit}) {
            return new Promise(resolve => {
                console.log(" 0000000000000000 ");
                myAxios.get('api/v1/appstate',
                    {
                        headers: {
                            "Content-Type": "application/json",
                            // "Access-Control-Allow-Origin": "*"
                        }
                    })
                    .then(
                        (resp) => {
                            var devices = resp.data.appStates
                            let check = false
                            if (sd) {
                                devices.forEach((device) => {
                                    if (device.deviceId === sd.id) check = true;
                                })
                                if (check === false) {
                                    localStorage.removeItem('selected-device');
                                }
                            }
                            commit('setAppstate', resp.data.appStates);
                            resolve();
                        }
                    )
            })
        },
        getAppState({commit}, id) {
            return new Promise(resolve => {
                myAxios.get('api/v1/appstate/' + id).then((resp) => {
                        var devices = this.state.devices.devices;
                        for (let i = 0; i < devices.length; i++) {
                            if (devices[i].deviceId == id) {
                                devices[i] = resp.data.appState;
                                break;
                            }
                        }
                        commit('setAppstate', devices);
                        resolve();
                    }
                )
            })
        },
        saveLocation({dispatch, commit}, lat, lon, zoomLevel) {
            commit('setLocation', {lat: lat, lon: lon, zoomLevel: zoomLevel})
        },
        getFiles({commit}, id) {
            myAxios.get("api/v1/userfiles?deviceId=" + id).then(
                (resp) => {
                    // console.log(resp.data.userFiles)
                    commit('setFiles', {data: resp.data.userFiles, id: id})
                }
            )
        }
    },
    mutations: {
        setSelectedDeviceId(state, id) {
            state.selectedDeviceId = id;
        },
        setSelectedDevice(state, device) {
            state.selectedDevice = device
        },
        setLocation(state, location) {
            state.location = location;
        },
        setAppstate(state, payload) {
            state.devices = payload;
        },
        setFiles(state, payload) {
            state.files[payload.id] = payload.data;
        }
    }
}