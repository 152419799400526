<template>
  <div class="small-black">

    <!--    <div v-if="currentCart.length > 0">-->
    <!--      Cells to order:-->
    <!--    </div>-->

    <!--    <div v-if="isOrdersLoading">-->
    <!--      <hr/>-->
    <!--      <b-spinner small type="grow" variant="warning"></b-spinner>-->
    <!--      <span class="small-gray">  loading...</span>-->
    <!--    </div>-->
    <div v-if="currentCart[0]">
      <div class="col-sm-3">
        <b-form-group v-slot="{ ariaDescribedby }">
          <b-form-radio-group
              id="btn-radios-1"
              v-model=selectedPeriod
              v-on:change=countTotalPrice
              size="sm"
              :options="subTypesList"
              :aria-describedby="ariaDescribedby"
              button-variant="outline-info"
              name="radios-btn-default"
              buttons
          ></b-form-radio-group>
        </b-form-group>
      </div>
    </div>

    <!--        <b-form-select-->
    <!--            :options="periodsList"-->
    <!--            plain-->
    <!--            class="smt9"-->
    <!--            v-model=selectedPeriod-->
    <!--        >-->
    <!--        </b-form-select>-->
    <!--      </div>-->
    <!--    </div>-->

    <div :key="index" v-for="(product, index) in currentCart.slice().reverse()">
      <details v-bind:open="isDetailsOpen(index)">
        <!--        <summary>{{ cell.type }}: {{ cell.id }}</summary>-->
        <summary>{{ product.id }}, <a href="#" @click="removeCellFromOrder(product.id)">
          <b-icon icon="x" variant="danger"></b-icon>
        </a></summary>
        <span class="small-gray">name:</span> {{ product.name }}<br/>
        <details>
          <summary class=small-gray>cells included: <span class="small-black">{{ product.cells.length }}</span>
          </summary>
          <ul>
            <li class=smt3 :key=index v-for="(cell, index) in product.cells">
              {{ cell.id }} {{ bandName(cellById(cell.id).usageBand) }}
            </li>
          </ul>
        </details>
        <!--        <span class="small-gray">price:</span> {{ cell.price }}$<br/>-->

        <!--        <span class="small-gray">info:</span> {{ cellInfo(cell.name) }}-->
      </details>
    </div>
    <br>

    <!--    <div v-if="isOrdersLoading()">-->
    <!--      <hr/>-->
    <!--      <b-spinner small type="grow" variant="warning"></b-spinner>-->
    <!--      <span class="small-gray">  loading...</span>-->
    <!--    </div>-->
    <!--    <div v-else>-->
    <!--      <div v-if="selected.device_id && !geocontent_list">-->
    <!--        <hr/>-->
    <!--        NO GEOCONTENT-->
    <!--      </div>-->
    <!--    </div>-->

    <div v-if="is_orders_loading || is_primar_loading || is_drawing">
      <!--      <hr/>-->
      <b-spinner small type="grow" variant="warning"></b-spinner>
      <span class="small-gray"> loading, please wait...</span>
    </div>
    <!--    <div v-if="orders.length > 0">-->
    <!--      <hr>-->
    <!--      History:-->
    <!--      <div class="small-cyan" :key="'orders-' + index" v-for="(order, index) in orders.slice().reverse()">-->
    <!--        <order :order=order :index=index :from_server=false></order>-->

    <!--      </div>-->
    <!--    </div>-->

    <!--    <div v-if="unpaidOrdersList.length > 0">-->
    <!--      <div class="small-cyan" :key="'ordersList-' + index" v-for="(order, index) in unpaidOrdersList.slice().reverse()">-->
    <!--        <order :order=order :index=index :from_server=true></order>-->
    <!--      </div>-->
    <!--    </div>-->

    <details class="small-gray" v-if="ecdis_summary.length > 0">
      <summary>
        Primar orders for ECDIS
      </summary>

      <div v-if="ecdis_summary.length > 0">

        <ul class="no-bullets">
          <li :key="index" v-for="(item, index) in ecdis_summary">
            <a class="small-cyan" href="#" @click="showCellOnMap(item, summary)">{{ item }}</a>
          </li>
        </ul>

      </div>
    </details>

    <div v-show="!is_orders_loading && !is_primar_loading && !is_drawing">
      <details class="small-gray" @click="summaryOpen()" v-if="summary.length > 0">
        <summary>
          All purchased ENCs
        </summary>
        <Scroll-Div width="300px" height="300px" view-class="yourclassname">

          <!--        <div><a href="#" @click="showTilesOnMap(summary)">Draw S63 on map</a></div><br>-->

          <div class="margin">
            <div>
              <ul class="no-bullets">
                <li :key="index" v-for="(item, index) in summary">
                  <details class="small-cyan">
                    <summary>
                      <a class="small-cyan" href="#" @click="showCellOnMap(item.id, summary)">{{ item.id }}</a>
                    </summary>
                    <span class="small-gray">Order ID:</span> <span class="small-black">{{ item.orderId }}</span><br>
                    <span class="small-gray">Valid until:</span> <span :class="isCellOutdated(item.expiredAt) ? 'smt6' : 'smt5'">{{
                      cellExpiredDate(item).date
                    }}</span><br>

                    <span class="small-gray">Subscription:</span> <span class="small-black">{{
                      item.monthCount
                    }} months</span><br>
                    <span class="small-gray">Band:</span> <span class="small-black">{{ bandName(item.band) }}</span><br>
                    <span class="small-gray">Edition/Update:</span> <span class="small-black">{{ item.edition }}/{{ item.update }}</span><br>

                  </details>
                </li>
              </ul>

            </div>
          </div>
        </Scroll-Div>

      </details>
    </div>
    <!--    <div>-->
    <!--      <details class="small-gray">-->
    <!--        <summary>-->
    <!--          this component vars-->
    <!--        </summary>-->
    <!--        {{this.$data}}-->

    <!--      </details>-->
    <!--    </div>-->
    <!--    <div>-->
    <!--      <details class="small-gray">-->
    <!--        <summary>-->
    <!--          parent component vars-->
    <!--        </summary>-->
    <!--        {{parentData}}-->
    <!--      </details>-->
    <!--    </div>-->
    <!--    <div>-->
    <!--      <details class="small-gray">-->
    <!--        <summary>-->
    <!--         selected cells-->
    <!--        </summary>-->
    <!--        <div>-->
    <!--          {{selectedCells}}-->
    <!--        </div>-->
    <!--      </details>-->
    <!--    </div>-->


    <div v-show="!purchase.isPrimarOrderCreating && !is_orders_loading && !is_primar_loading  && !is_drawing">

      <details class="small-gray" v-if="ordersList.length > 0">
        <summary>
          Orders history
        </summary>

        <!--        <div>-->
        <!--          <order :alt=true :open=true :order="unpaidOrdersList.slice().reverse()[0]" :index=0>-->
        <!--          </order>-->
        <!--        </div>-->

        <div v-show=!hideHistory>
          <Scroll-Div width="300px" height="300px" view-class="yourclassname">
            <div class="margin" v-if="ordersList.length > 0">
              <div class="small-cyan" :key="'ordersList-' + index"
                   v-for="(order, index) in ordersList.slice().reverse()">
                <order :map="map" :open="index === 0" :order=order :index=index :from_server=true></order>
              </div>
            </div>
          </Scroll-Div>

          <!--          <details class="small-gray" v-if="unpaidOrdersList.length > 0">-->
          <!--            <summary>-->
          <!--              New orders ({{ unpaidOrdersList.length }})-->
          <!--            </summary>-->
          <!--            <div class="margin" v-if="unpaidOrdersList.length > 0">-->
          <!--              <div class="small-cyan" :key="'unpaidOrdersList-' + index"-->
          <!--                   v-for="(order, index) in unpaidOrdersList.slice().reverse()">-->
          <!--                <order :open="index === 0" :order=order :index=index :from_server=true></order>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--          </details>-->

          <!--          <details class="small-gray" v-if="processingOrdersList.length > 0">-->
          <!--            <summary>-->
          <!--              Processing orders ({{ processingOrdersList.length }})-->
          <!--            </summary>-->
          <!--            <div class="margin" v-if="processingOrdersList.length > 0">-->
          <!--              <div class="small-cyan" :key="'processingOrdersList-' + index"-->
          <!--                   v-for="(order, index) in processingOrdersList.slice().reverse()">-->
          <!--                <order :order=order :index=index :from_server=true></order>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--          </details>-->

          <!--          <details class="small-gray" v-if="errorOrdersList.length > 0">-->
          <!--            <summary>-->
          <!--              Orders with errors ({{ errorOrdersList.length }})-->
          <!--            </summary>-->
          <!--            <div class="margin" v-if="errorOrdersList.length > 0">-->
          <!--              <div class="small-cyan" :key="'errorOrdersList-' + index"-->
          <!--                   v-for="(order, index) in errorOrdersList.slice().reverse()">-->
          <!--                <order :order=order :index=index :from_server=true></order>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--          </details>-->

          <!--          <details class="small-gray" v-if="completedOrdersList.length > 0">-->
          <!--            <summary>-->
          <!--              Completed orders ({{ completedOrdersList.length }})-->
          <!--            </summary>-->
          <!--            <div class="margin" v-if="completedOrdersList.length > 0">-->
          <!--              <div class="small-cyan" :key="'completedOrdersList-' + index"-->
          <!--                   v-for="(order, index) in completedOrdersList.slice().reverse()">-->
          <!--                <order :order=order :index=index :from_server=true></order>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--          </details>-->
        </div>

      </details>

    </div>


  </div>
</template>

<script>

import S63order from "@/views/charts/S63order";
import {getS63CKTInfo} from "@/utils/utils";
import {eventBus} from "@/main";
import myAxios from "@/services/myAxios";
import * as utils from "@/utils/utils";
import {lastIndexOf} from "lodash";
import {file} from "paths.macro";
import {CURRENT_PRODUCT} from "@/utils/build-config";

export default {
  components: {Order: S63order},
  props: ['map', 'purchase', 'hideHistory', 'parentData'],
  data() {
    return {
      selectedPeriod: CURRENT_PRODUCT.is_navicom ? "0" : "4"
    }
  },
  computed: {
    currentCart() {
      return this.$store.state.current.cart.s63cells;
    },
    is_orders_loading() {
      return this.$store.state.current.loading.orders;
    },
    is_primar_loading() {
      return this.$store.state.current.loading.primarInfo;
    },
    is_drawing() {
      return this.$store.state.current.loading.drawing;
    },
    orders() {
      return this.$store.state.charts.s63.orders;
    },
    ordersList() {
      return this.$store.state.charts.s63.orders_list;
    },
    summary() {
      return this.$store.state.charts.s63.summary;
    },
    ecdis_summary() {
      return this.$store.state.charts.s63.ecdis_summary;
    },
    completedOrdersList() {
      let list = this.$store.state.charts.s63.orders_list.filter(function (e) {
        return e.status === 'complete';
      });

      return list;
    },
    errorOrdersList() {
      let list = this.$store.state.charts.s63.orders_list.filter(function (e) {
        return e.status === 'error';
      });

      return list;
    },
    processingOrdersList() {
      let list = this.$store.state.charts.s63.orders_list.filter(function (e) {
        return e.status != 'new' && e.status != 'complete' && e.status != 'error';
      });

      return list;
    },
    unpaidOrdersList() {
      let list = this.$store.state.charts.s63.orders_list.filter(function (e) {
        return e.status === 'new';
      });

      return list;
    },
    // selectedCells(){
    //   return this.$store.state.charts.s63.items;
    // },
    periodsList() {
      return this.$store.state.current.cart.currentBandPeriods;
    },
    subTypesList() {
      let pList = this.periodsList;
      let myList = [];
      Object.keys(pList).forEach(x => {
        myList.push({value: x, text: pList[x]})
      })
      console.log(myList, "my list")
      return myList.reverse()
    }
  },
  watch: {
    // currentCart(val){
    //   this.$store.dispatch("current/getTotalPrice", this.currentCart, this.selectedPeriod)
    // },
    periodsList(val) {
      // if (Object.keys(val) && !Object.keys(val).includes(this.selectedPeriod)) {
      let list = Object.keys(val);
      if (!this.selectedPeriod) {
        this.selectedPeriod = "4"
      } else if (!list.includes(this.selectedPeriod)) {
        this.selectedPeriod = list[list.length - 1]
      }

      // }
    },
    currentCart() {
      this.countTotalPrice();
    },
    // periodsList(val){
    //   this.selectedPeriod = Object.keys(val).includes({0: 12, 1: 6, 4: 3}[this.selectedPeriod]) ? this.selectedPeriod : Object.keys(val)[0]
    //   console.log(this.selectedPeriod, 'aaaa')
    // },
    is_orders_loading(val) {
      console.log(val)

    },
    // selectedCells(items, old) {
    //   if (items.length > old.length)
    //     items.forEach(chart => {
    //       if(!this.currentCart.find(x => {return x.id == chart.id}))
    //       {
    //         let chartInfo = getS63CKTInfo(chart.id);
    //         console.log(chartInfo.period)
    //         this.currentCart.push(
    //             {
    //               id: chart.id,
    //               name: chart.title,
    //               selectedPeriod: this.selectedPeriod,
    //               price: undefined,
    //               periods: chartInfo.period
    //               // selectedPeriod: chartInfo.period[chartInfo.period.length - 1],
    //             },
    //         )
    //       }
    //     })
    //   else  {
    //     let  arr1 = [];
    //     items.forEach(x => {
    //       arr1.push(x.id);
    //     })
    //     let arr2 = [];
    //     this.currentCart.forEach(x => {
    //       arr2.push(x.id);
    //     })
    //     let difference = arr2.filter(x => !arr1.includes(x));
    //     console.log(difference)
    //     this.currentCart = this.currentCart.filter(x => x.id !== difference[0]);
    //   }
    //   this.countAll();
    //   this.$store.dispatch("current/getTotalPrice", {cells: this.currentCart, period: this.selectedPeriod})
    // }
  },
  methods: {
    cellById(id) {
      return this.$store.getters.cellById(id)
    },
    countTotalPrice() {
      // hide price while change period
      this.$store.commit("current/setTotalPriceByPrimar",
          { usd: '?', rub: '?', rubineuro: '?' }
      );
      this.$store.dispatch("current/getTotalPrice", {products: this.currentCart, periodBand: this.selectedPeriod});
    },
    removeCellFromOrder(id) {
      eventBus.$emit('cell-clicked', id);
    },
    countAll() {
      this.currentCart.forEach(cell => {
        console.log(this.currentCart)
        this.countPrice(cell)
      })
    },
    bandName(code) {
      return utils.getBandName(code);
    },
    showCellOnMap(id, items) {

      eventBus.$emit('pane2cell', {id: id, items: items, color: 'yellow'})

      const url = 'https://win1hetz.bgeo.fi:5005/' + id + '/{z}/{x}/{y}'

      // this.map.setSpecialTiles(url);
      eventBus.$emit('show-cell-on-map', url)
    },
    showTilesOnMap(items) {

      let id = this.$store.state.current.geocontent.s63_id;

      myAxios.get('https://win1hetz.bgeo.fi:5005/run?id=' + id + '&isIdFromGc=true').then(resp => {
        if (resp.status === 200) {
          const url = resp.data + "map/{z}/{x}/{y}";
          this.map.setSpecialTiles(url)
        }
        if (resp.status === 204) {
          console.log(" 204 error")
        }
      });
    },
    cellExpiredDate(cell) {
      return utils.formatDate(cell.expiredAt);
    },
    isCellOutdated(date) {
      let days = utils.daysOldWithSign(date);
      return days <= 0;
    },
    isDetailsOpen(index) {
      if (index > 0) {
        return false;
      }

      return true;
    },
    summaryOpen() {
      // this.map.ChartsLayers.unavailable.addTo(this.map)
      eventBus.$emit('summary-open')
    },
    countPrice(cell) {
      // let k = this.selectedPeriod < 10 ? this.selectedPeriod / 10 : 1;
      // cell.price = Number((cell.pricePerYear * k).toFixed(2));

      let quotate = {
        items: [cell.id],
        subscriptionType: this.selectedPeriod
      };
      // alert(4)
      myAxios.post('/api/v1/orders/primar/quotate/v2', quotate).then(resp => {
        cell.price = Number(resp.data.usd).toFixed(2);
      })
    },
    async countPriceInit(cell) {
      // let k = selectedPeriod < 10 ? selectedPeriod / 10 : 1;
      // let price = Number((pricePerYear * k).toFixed(2));

      let quotate = {
        items: [cell.id],
        subscriptionType: this.selectedPeriod
      };
      let res
      // alert(5)
      myAxios.post('/api/v1/orders/primar/quotate/v2', quotate).then(resp => {
        // cell.price = price;
        res = Number(resp.data.usd).toFixed(2);
      })
      return res;
    },
    onOrderCreation() {
      this.$emit('purchase-clicked');
    },
    cellInfo(cellName) {
      // console.log(cellName)
      // console.log(getS63CKTInfo(cellName))
      return getS63CKTInfo(cellName);
    }
  },
  created() {
    eventBus.$on("s63-order-finished", data => {
      // // this.$store.commit("current/setTotalPrice", 0);
      // this.$store.dispatch('current/addS63Cart',
      //     {
      //       s63cells: this.currentCart,
      //     });
      //
      // this.currentCart = [];
    });
  }
}
</script>

<style scoped>

ul.no-bullets {
  list-style-type: none; /* Remove bullets */
  padding: 0; /* Remove padding */
  margin: 0; /* Remove margins */
}

</style>