<template>
  <div id="mymap" ref="mm" :style="{ height: height + 'px', minHeight: '400px'}"></div>
</template>

<script>
import "leaflet/dist/leaflet.css";
import L from "leaflet";

export default {
  props: ['height', 'map', 'showRoute'],
  // computed: {
  //   windowHeight(){
  //     if (this.$route.name === 'data') return window.innerHeight - 150;
  //     return window.innerHeight - 90;
  //   }
  // },
  mounted() {
    this.map.init(this.$refs.mm, this.showRoute)
  },
};
</script>

<style >
#mymap {
  border: solid 1px dimgray;
}
.leaflet-interactive:hover{
  stroke: #ffa011;
  stroke-width: 4;
}
/*.leaflet-interactive{*/
/*  stroke: #000000;*/
/*  stroke-width: 1;*/
/*}*/
</style>