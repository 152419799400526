<template>
  <div>
    <b-card class="mt-2 small-black" bg-variant="light" text-variant="black" header="DEVICE">
      <div v-if="data.device_id"><span class="small-gray">ID:</span> {{ data.device_id }}<br></div>
      <div v-if="data.email"><span class="small-gray">Email:</span> {{ data.email }}<br></div>
      <div v-if="data.device_os"><span class="small-gray">Platform:</span> {{ data.device_os }}<br></div>
      <div v-if="data.app_name"><span class="small-gray">App:</span> {{ data.app_name }}<br></div>
      <div v-if="data.app_version"><span class="small-gray">Ver:</span> {{ data.app_version }}<br></div>
    </b-card>
  </div>
</template>

<script>
import {file} from 'paths.macro';

export default {
  computed: {
    data() {
      return this.$store.state.current.profile;
    },
    selectedId() {
      return this.$store.state.devices.selectedDeviceId;
    }
  },
  methods: {
  },
}
</script>

