import L from 'leaflet';

import 'bootstrap-icons/font/bootstrap-icons.css'
import 'bootstrap'
import "bootstrap/dist/css/bootstrap.css"
// import "./extensions/leaflet.scalefactor.css"
// import "./extensions/leaflet.scalefactor.js"
import "./extensions/L.Control.MapCenterCoord.min.js"
import "./extensions/L.Control.MapCenterCoord.min.css"

import store from "@/store";

export class MyLeaf {
    init(el, url, center, zoom, minZoom=4, maxZoom=17) {
        this.map = new L.map(el,

        ).setView(center, zoom);

        var southWest = L.latLng(-85, -180.0),
            northEast = L.latLng(85, 180.0),
            mybounds = L.latLngBounds(southWest, northEast);

        this.currentMapsURL = url;
        this.mainLayer = L.tileLayer(this.currentMapsURL, {
            minZoom: minZoom,
            maxZoom: maxZoom,
            maxBounds: mybounds,
            // attribution: "Tiles &copy; Esri &mdash; Sources: GEBCO, NOAA, CHS, OSU, UNH, CSUMB, National Geographic, DeLorme, NAVTEQ, and Esri"
            attribution: "\"Map data &copy; <a href='http://probki.net/b2b'>Geophone OY</a>"
        }).addTo(this.map);
        // L.control.scalefactor({position: 'bottomleft'}).addTo(this.map);
        L.control.mapCenterCoord().addTo(this.map);
        // this.map('map', { searchControl: {layer: this.mainLayer} });
        // L.control.search("aaa").addTo(this.map);
        // L.control.searchControl().addTo(this.map);

        //
        // L.control.searchControl().addTo(this.map)

        this.map.on('moveend', () => {
            let coorinates = this.map.getCenter();
            // localStorage.setItem('mapCenter', JSON.stringify([coorinates.lat, coorinates.lng]));
            store.commit("leaflet/setMapCenter", coorinates)
        })

        this.map.on('zoomend', () => {
            let zoom = this.map.getZoom();
            // localStorage.setItem('mapZoom', JSON.stringify(zoom));
            store.commit("leaflet/setMapZoom", zoom)
        })

        this.map.setMaxBounds(mybounds)
        return this.map;
    }
    moveTo(lat, lon) {
        this.map.setView([lat, lon]);
    }
    panToZone(bounds) {
        this.map.fitBounds(bounds);
    }
    redrawMap(URL, coordinates, zoom_level) {
        this.mainLayer.remove();
        this.map.setView(coordinates, zoom_level);
        this.mainLayer = L.tileLayer(URL);
        this.mainLayer.addTo(this.map);
    }
}




