<template>
  <div v-show=active>
    <hr/>
    <b-spinner small type="grow" variant="warning"></b-spinner>
    <span class="small-gray"> {{message ? message : ` loading...`}}</span>
  </div>
</template>

<script>
export default {
  name: "MySpinner",
  props: ['active', `message`]
}
</script>

<style scoped>

</style>