<template>
  <div>

    <b-form-group label-cols="5" label-size="sm" label="Draught (m)">
      <b-form-spinbutton id="sb-inline-draught" v-model="routes.draught" min="1" step="0.2" max="25" locale="en"></b-form-spinbutton>
    </b-form-group>

    <b-form-group label-cols="5" label-size="sm" label="Height (m)">
      <b-form-spinbutton id="sb-inline-height" v-model="routes.height" min="1" step="0.2" max="50" locale="en"></b-form-spinbutton>
    </b-form-group>

    <b-form-group label-cols="5" label-size="sm" label="Radius (m)">
      <b-form-spinbutton id="sb-inline-radius" v-model="routes.radius" min="10" step="10" max="500" locale="en"></b-form-spinbutton>
    </b-form-group>

    <b-form-group label-cols="5" label-size="sm" label="XTE.L (m)">
      <b-form-spinbutton id="sb-inline-xte-l" v-model="routes.xte_left" min="10" step="10" max="500" locale="en"></b-form-spinbutton>
    </b-form-group>

    <b-form-group label-cols="5" label-size="sm" label="XTE.R (m)">
      <b-form-spinbutton id="sb-inline-xte-r" v-model="routes.xte_right" min="10" step="10" max="500" locale="en"></b-form-spinbutton>

<!--      <b-button class="mt-2" @click="changeDraught()" variant="info">Calculate route</b-button>-->
    </b-form-group>



<!--    <b-form-group label-cols="4" label-cols-lg="3" label-size="sm" label="Draught:" label-for="input-sm">-->
<!--      <b-form-spinbutton id="sb-inline" v-model="routes.draught" min="1" step="0.2" max="25" locale="en"></b-form-spinbutton>-->
<!--      <b-button class="mt-2" @click="changeDraught()" variant="info">Accept new draught</b-button>-->
<!--    </b-form-group>-->

  </div>
</template>

<script>

import {eventBus} from "@/main";
import {commit} from "lodash/seq";
import store from "@/store";

export default {
  data() {
    return {
      routes: {
        draught: this.$store.state.current.ship.draught > 0 ? this.$store.state.current.ship.draught : 10,
        height: 20,
        radius: 120,
        xte_left: 100,
        xte_right: 100,
      }
    }
  },
  mounted() {
    this.timer = setInterval(this.checkDepthSettings, 5000);
  },
  beforeDestroy() {
    clearInterval(this.timer);
    // store.commit("routes/resetStorage");
  },
  methods: {
    checkDepthSettings() {
      eventBus.$emit('change-nogo');
    },
    // changeDraught() {
    //   eventBus.$emit('change-draught');
    // }
  },
}
</script>
