import myAxios from "@/services/myAxios";
import {file} from "paths.macro";
import {commit} from "lodash/seq";
import axios from "axios";

const initialState = {
    progress: {
        isCalculating: false,
        calculatingProgress: 0,
    },
    route: [],
    length: '',
    dangers: [],
}

export const routes = {
    namespaced: true,
    state: initialState,
    actions: {
    },
    mutations: {
        setCalculatingStatus(state, status) {
            state.progress.isCalculating = status;
            if (status) {
                state.progress.calculatingProgress = 0;
            }
        },
        setProgress(state, progress) {
            state.progress.calculatingProgress = progress;
        },
        setRoute(state, payload) {
            state.route = payload.route;
            state.length = Number(payload.length).toFixed(2);
            state.dangers = payload.dangers;
        },
        setRoutePoints(state, payload){
            state.route = payload;
        },
        resetStorage(state) {
            //state = initialState;
            //Object.assign(state, initialState)
            state.route = [];
            state.dangers = [];
            state.length = '';
            state.progress.isCalculating = false;
            state.progress.calculatingProgress = 0;
        }
    }
}