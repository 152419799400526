<template>
  <div>

    <b-modal id="modal-primar-error" title="Error" v-model="network.isError" ok-only>
      <p class="my-4">Error {{network.code}}: chart purchasing in temporary unavailable. Try reload the page later. </p>
    </b-modal>

    <b-modal v-if="!IS_NP()" id="modal-charts" title="Hint" v-model="showHint" ok-only @ok="okHandler">
      <p class="my-4">To purchase Primar maps press <b>New order</b> and select any preferable method (e.g. manual cell selection).</p>
      <img src="https://mkartweb.com/pics/hint_02.png" width="200">
      <p class="my-4">For any questions please write us to <a href="mailto:support@mkart.fi">support@mkart.fi</a> </p>
    </b-modal>

    <div v-if="!hasData" class="alert alert-warning">
      Please select the device with geo content and a registered user first!
    </div>

    <!-- sidebar ------------------------------------------>
    <b-sidebar width="800px" id="charts-sidebar-1" shadow>
      <div class="px-3 py-2">
        <b-table id="content-table" class="secondary text-nowrap mt-3" tbody-class="small-black" thead-tr-class="small-gray"
                 sticky-header="550px"
                 responsive hover
                 :fields="['name', 'description', 'edition', 'update', 'releaseDate', 'scale', 'band']"
                 :items=charts_collection>

        </b-table>
      </div>
    </b-sidebar>

    <b-container fluid>
      <b-row>

        <b-col cols="5">
          <b-row>
            <b-col cols="6">
              <b-badge variant="light">PRODUCTS</b-badge>
              <br><br>
              <div class="border-right" style="height: 800px; overflow-y: scroll;">
                <!-- mydata ------------------------------------------>
                <products v-if="mydata_list.length > 0" :map=map :parentIsOutdated="isOutdated"
                          :content="mydata_list" :product_type="'user'">
                </products>
<!--                <hr v-if="mydata_list.length > 0">-->

                <!-- s63 --------------------------------------------->
                <products v-if="s63_list.length > 0" :map=map :parentIsOutdated="isOutdated" :content="s63_list"
                          :product_type="'s63'">
                </products>
<!--                <hr v-if="s63_list.length > 0">-->

                <!-- products ---------------------------------------->
                <products v-if="products_list.length > 0" :map=map :parentIsOutdated="isOutdated"
                          :content="products_list" :product_type="'prods'">
                </products>
<!--                <hr v-if="products_list.length > 0">-->
              </div>
            </b-col>

            <b-col cols="6">
              <b-badge variant="light">LICENSING</b-badge>
              <br><br>
              <b-row>
                <licensing></licensing>
              </b-row>
              <b-row>
                <div>
                  <purchasing @purchase-clicked=onOrderCreation :license="licensing"
                              :parentIsOutdated="isOutdated"
                              :map=map
                  ></purchasing>
                </div>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="7">
          <b-badge variant="light">MAP</b-badge>
          <br><br>
          <div>
            <div>
              <my-map :map=map :show-route=haveRoute :height="mapHeight"></my-map>
            </div>
          </div>
        </b-col>

      </b-row>
    </b-container>
  </div>
</template>

<script>
import Products from "@/views/charts/Products";
import Licensing from "@/views/charts/Licensing";
import Purchasing from "@/views/charts/Purchasing";
import ChartsMap from "@/views/charts/ChartsMap.vue";
import * as utils from "@/utils/utils";
import {ChartsLeaf} from "../../my-leaf/charts-leaf";
import {file} from "paths.macro";
import {eventBus} from "@/main";
import {IS_NP} from "@/utils/build-config";

export default {
  components: {Products, Licensing, Purchasing, MyMap: ChartsMap},
  data() {
    return {

      showHint: false,

      network: {
        isError: false,
        code: '',
      },

      // openDialog: true,
      map: new ChartsLeaf(true),
      // haveRoute: this.$store.state.charts.profile.appState.routeJson > 0,
      haveRoute: false,
      log: "",
      chart_types: [
        {title: "Products"},
        {title: "S63/Primar"},
      ],
      licensing: {},
      selected: 0,
      mapHeight: window.innerHeight / 1.2,
      bagSelected: false
    }
  },
  computed: {
    is_orders_loading() {
      return this.$store.state.current.loading.orders;
    },
    is_primar_loading() {
      return this.$store.state.current.loading.primarInfo;
    },
    is_drawing() {
      return this.$store.state.current.loading.drawing;
    },
    geocontent_list() {
      utils.dumpFile("selected_geocontent", this.$store.state.manage.selected_geocontent)
      return this.$store.state.manage.selected_geocontent;
    },
    products_list() {
      let items = [];

      utils.dumpFile("selected_geocontent", this.$store.state.manage.selected_geocontent)

      if (this.$store.state.manage.selected_geocontent) {
        items = this.$store.state.manage.selected_geocontent.filter(function (e) {
          return e["title"] != "PRIMAR Data (S63)" && e["title"] != "User's Data";
        });
      }
      return items;
    },
    s63_list() {
      let items = [];

      if (this.$store.state.manage.selected_geocontent) {
        items = this.$store.state.manage.selected_geocontent.filter(function (e) {
          return e["title"] === "PRIMAR Data (S63)";
        });
      }
      return items;
    },
    mydata_list() {
      let items = [];

      if (this.$store.state.manage.selected_geocontent) {
        items = this.$store.state.manage.selected_geocontent.filter(function (e) {
          return e["title"] === "User's Data";
        });
      }
      return items;
    },
    charts() {
      return this.$store.state.charts.s63.items;
    },
    hasData() {
      // alert(this.$store.state.current.profile.device_id.length, this.$store.state.current.profile.email.length, this.$store.state.manage.selected_geocontent)
      return this.$store.state.current.profile.device_id.length > 0 &&
          this.$store.state.current.profile.email.length > 0 &&
          this.$store.state.manage.selected_geocontent;
    },
    // selectedDevice() {
    //   return this.$store.state.devices.selectedDevice;
    // },
    // selectedGeoContent() {
    //   return this.$store.state.manage.selected_geocontent;
    // },
  },
  watch: {
    tab_selected(value) {

    }
  },
  methods: {
    IS_NP() {
      return IS_NP
    },
    productsByProduct(product) {
      let str = [];

      if (product) {
        product.forEach((prod) => {
          // str += prod.type + ", ";
          str.push(prod.type);
        });
      }
      return str;
    },
    formatDate(date) {
      return utils.formatDate(date).date;
    },
    onOrderCreation(payload) {
      let keys = [];

      this.charts.forEach(item => {
        console.log(item)
        keys.push({id: item.name, monthCount: "" + item.period, price: "" + item.price});
      })
      this.$store.dispatch('addS63Order', {keys: keys, price: payload.price, uuid: payload.uuid});
      this.$store.dispatch('getENCs').then(() => {
        this.$store.dispatch("getS63Summary", {
          device_id: this.$store.state.current.profile.device_id,
          user_id: this.$store.state.current.profile.user_id,
        }).then(() => {
          console.log('init draw all cells from', file)

          this.map.drawAllCells(this.$store.state.charts.s63.primar_map_info);
        })
      })
    },
    allProducts() {
      let all_prods = this.$store.state.manage.products;
      let prods = this.$store.state.charts.profile.products;

      var dataArray = [];

      prods.forEach((inst) => {
        // console.log(inst.productInfo.productId)
        dataArray.push(inst.productInfo.productId);

        all_prods = all_prods.filter(function (e) {
          return e["productId"] != inst.productInfo.productId && e["licenseType"] != "corp";
        });
      });

      // all_prods = all_prods.filter(function (e) {
      //   return e["licenseType"] != "corp";
      // });

      return all_prods;

    },
    charts_collection() {
      return this.$store.state.manage.charts_collection;
    },
    // products() {
    //   return this.$store.state.charts.profile.products;
    // },
    productContent(product) {
      let str = "";

      if (product.enc)
        str += "ENCs, ";
      if (product.s102)
        str += "S102, ";
      if (product.poi)
        str += "POI, ";
      if (product.ntm)
        str += "NTM, ";
      if (product.ais)
        str += "AIS, ";
      if (product.weather)
        str += "Weather, ";
      if (product.navMsg)
        str += "Nav.Messages, ";
      if (product.noaaWeather)
        str += "NOAA Weather, ";
      if (product.pollution)
        str += "Pollution, ";

      str = utils.truncStrng(str, 20);

      return str;
    },
    isOutdated(date, type) {
      if (type.toLowerCase() === "free") {
        return "success";
      }
      let days = utils.daysOldWithSign(date);
      return days <= 0 ? "danger" : "success";
    },
    okHandler() {
      // this.$store.commit("current/setHintsCharts", false)
      localStorage.setItem('disable-charts-hint', "true") ;
    },
    // clearECDIS() {
    //   this.$store.dispatch("clearECDIS")
    // },

  },
  created() {
  },
  mounted() {

    this.showHint = localStorage.getItem('disable-charts-hint') === null ? true : false ;
    console.log(this.showHint)
    // localStorage.removeItem('disable-charts-hint');


    // let x = localStorage.getItem('charts-hint');
    // console.log(x)
    // localStorage.setItem('charts-hint', true);
    // x = localStorage.getItem('charts-hint');
    // console.log(x)
    // localStorage.removeItem('charts-hint');
    // x = localStorage.getItem('charts-hint');
    // console.log(x)


    eventBus.$on('network-error', (value) => {
      this.network.isError = true;
      this.network.code = value;
    })


    // this.$store.dispatch("clearECDIS")
    console.log(this.$store.state.current.profile.device_id)
    console.log(this.$store.state.current.profile.user_id)
    this.$store.dispatch('getS63OrdersList', {
      device_id: this.$store.state.current.profile.device_id,
      user_id: this.$store.state.current.profile.user_id
    }).then(() => {
      console.log(" getS63OrdersList finished")
    });
  //
  //   console.log(" ************ ")
  //   console.log(" getS63Summary started")
  //   this.$store.commit("current/pushLoadingMarker", "charts|AAAA2")
  //   this.$store.dispatch('getS63Summary', {
  //     device_id: this.$store.state.current.profile.device_id,
  //     user_id: this.$store.state.current.profile.user_id
  //   }).then(() => {
  //     console.log(" ************ ")
  //     console.log(" getS63Summary finished")
  //
  //     this.$store.dispatch('current/loadingOrders', false);
  //     this.$store.dispatch('current/removeLoadingMarker', "orders|mounted");
  //   });


    // this.orto = setInterval(() => {
    //   this.$store.dispatch('getS63OrdersList', {device_id: this.$store.state.current.profile.device_id,
    //     user_id: this.$store.state.current.profile.user_id});
    // },
    //     30000
    // )
  },
  beforeDestroy() {
    this.map.clearCellslayer()
    clearInterval(this.orto);
    this.$store.commit("setAllSummaryClear");
  }
}
</script>

<style scoped>
</style>
