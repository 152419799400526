import Manage from "@/views/manage";
import Login from "@/views/Login.vue";
import Devices from "@/views/devices";
import Routes from "@/views/routes";
import Charts from "@/views/charts";
import Ecdis from "@/views/ecdis";
import Viewer from "@/views/viewer";
import PaymentStatus from "@/views/PaymentStatus";
import {
    ActiveTab
} from "@/utils/config";
import Loader from "@/views/paymentBuffers/Loader";
import Mobile from "@/views/Mobile";
import {IS_NP} from "@/utils/build-config";

export default [{
        id: 0,
        path: '/',
        name: 'home',
        redirect: 'devices',
        meta: {
            guest: false
        }
    },
    {
        id: 1,
        path: '/login',
        name: 'login',
        component: Login,
        meta: {
            guest: true
        },
        props: (route) => ({ mconnect: route.query.mconnect })
    },
    {
        id: 2,
        path: '/devices',
        name: 'devices',
        components: {
            default: Devices
        },
        meta: {
            id: ActiveTab.DEVICES
        },
    },
    {
        id: 3,
        path: '/charts',
        name: 'charts',
        components: {
            default: Charts
        },
    },
    {
        id: 4,
        path: '/manage',
        name: 'manage',
        component: Manage,
        meta: {
            guest: false,
            id: ActiveTab.MANAGE
        },
    },
    {
        id: 5,
        path: '/payment-status/:status',
        name: 'payment-status',
        component: PaymentStatus
    },
    {
        id: 6,
        component: Loader,
        path: "/loader",
        name: 'loader',
        // props: true
    },
    {
        id: 7,
        component: Mobile,
        path: "/mobile",
        name: 'mobile',
        meta: {
            guest: true
        },
        props: (route) => ({ username: route.query.u, password: route.query.p })
    },
    {
        id: 8,
        path: '/routes',
        name: 'routes',
        components: {
            default: Routes
        },
        meta: {
            guest: false,
            id: ActiveTab.ROUTES
        },
    },
    {
        id: 9,
        path: '/ecdis',
        name: 'ecdis',
        components: {
            default: Ecdis
        },
        meta: {
            guest: false,
        },
    },
    {
        id: 10,
        path: '/viewer',
        name: 'viewer',
        components: {
            default: Viewer
        },
        meta: {
            guest: true,
        },
    },
]