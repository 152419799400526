<template>
  <div v-if="files_list !== undefined && files_list.length > 0" class="accordion" role="tablist">
    <details>
      <summary class="small-gray">Statistic...</summary>
      <div class="small-gray">
        <div v-for="(file, index) in files_list" :key="index">
          <a role="button" class="primary" @click="downloadFile(file.id, file.name)">
            {{ file.name }}
          </a>
        </div>
      </div>
    </details>
  </div>
</template>

<script>
import myAxios from "@/services/myAxios";
import {file} from 'paths.macro';

export default {
  props: ["files_list"],
  methods: {
    downloadFile(id, name) {
      myAxios.get('api/v1/userfile/' + id, {responseType: 'blob'})
          .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            console.log(file, "downloadFile: " + url);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', name); //or any other extension
            document.body.appendChild(link);
            link.click();
          });
    }
  }
}
</script>
