<template>
  <div class="row">
    <div class="col-12">
      <div class="row">
        <div class="col-5">
          <div class="row">

            <div class="col-6 border-right" style="height: 800px; overflow-y: scroll;">
              <b-badge variant="light">DEVICE</b-badge>
              <device></device>
              <br>

              <b-badge variant="light">ROUTE. GENERAL SETTINGS</b-badge>
              <br><br>
              <routes></routes>
              <br/>

            </div>

            <div class="col-6 border-right" style="height: 800px; overflow-y: scroll;">
              <b-badge variant="light">VOYAGE PLAN</b-badge>
              <br><br>
              <voyage-plan></voyage-plan>
            </div>
          </div>
        </div>

        <div class="col-7">
          <my-map :map=map :height=mapHeight></my-map>
        </div>

      </div>
    </div>

  </div>
</template>

<script>
import RoutesMap from "@/views/routes/RoutesMap";
import Device from "@/views/routes/Device.vue"
import Routes from "@/views/routes/Routes.vue";
import VoyagePlan from "@/views/routes/VoyagePlan.vue";
import {RouteLeaf} from "../../my-leaf/route-leaf";

export default {
  components: {MyMap: RoutesMap, Routes, Device, VoyagePlan},
  data() {
    return {
      map: new RouteLeaf(),
      mapHeight: window.innerHeight / 1.2,
    }
  },
  methods: {
  },
}
</script>
