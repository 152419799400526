import {file} from 'paths.macro';

export function authHeader() {
    let user = JSON.parse(localStorage.getItem('user'));
    if (user && user.token) {
        // if (process.env.NODE_ENV === "development") {
        //
        // }
        return { 'Authorization': 'Bearer ' + user.token };
    }
    else {
        return {};
    }
}