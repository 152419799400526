<template>
  <div>
    <div class="d-flex justify-content-center align-items-center mt-4">
      <b-alert variant="outline-info" show>
        <h3>
          Rotate your phone to landscape and press Login button
        </h3>
      </b-alert>
    </div>
    <div class="d-flex justify-content-center align-items-center mt-4">
      <b-button variant="outline-success" :to="{name: 'login'}">Login</b-button>
    </div>
  </div>
</template>

<script>
import router from "@/router";

export default {
  name: "Mobile",
  props: ['username', 'password'],
  mounted() {
    if (this.username && this.password){
      this.$store.dispatch("auth/login", {
        username: this.username,
        password: this.password
      });
    }
  }
}
</script>

<style scoped>
/*.d-flex{*/
/*  position: absolute; top: 50%; left: 50%;*/
/*}*/
</style>