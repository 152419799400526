import Vue from 'vue'
import Vuex from 'vuex'
import {devices} from "./modules/devices";
import {auth} from "@/store/modules/auth";
import {alert} from "@/store/modules/alert";
import {charts} from "@/store/modules/charts";
import {manage} from "@/store/modules/manage";
import {debug} from "@/store/modules/debug"
import {current} from "@/store/modules/current";
import {routes} from "@/store/modules/routes";
import {leaflet} from "@/store/modules/leaflet";
import createPersistedState from 'vuex-persistedstate'


Vue.use(Vuex)

export default new Vuex.Store({
    state: {}, mutations: {}, actions: {}, modules: {
        devices, auth, alert, charts, manage, current, debug, routes, leaflet
    },

    plugins: [createPersistedState({
        storage: window.sessionStorage,
        paths: ['leaflet']
    })],
})
