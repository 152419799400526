import axios from 'axios';
import {authHeader} from '@/helpers/authHeader'
import router from "../router";


const myAxios = axios.create({
    baseURL: process.env.NODE_ENV === 'development' ? process.env.VUE_APP_API_PROTOCOL + process.env.VUE_APP_API_BASE_URL : process.env.VUE_APP_API_PROTOCOL + process.env.VUE_APP_API_BASE_URL,
    headers: {'Access-Control-Allow-Origin': '*'}
});

myAxios.interceptors.request.use(
    function (config) {
        config.headers.Authorization = '';
        let hdr = authHeader();
        if (hdr.Authorization) {
            config.headers.Authorization = hdr.Authorization;
        }
        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);

myAxios.interceptors.response.use(
    function (response) {
        if (response.headers["newone"]) {
            var user = JSON.parse(localStorage.getItem("user"));
            user.token = response.headers["newone"];
            localStorage.setItem('user', JSON.stringify(user));
        }
        return response;
    },
    function (error) {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('user');
            router.push({name: "login"});
        }
        return Promise.reject(error);
    }
);

export default myAxios;