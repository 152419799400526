<template>
  <div>

<!--    <pre class="m-0">{{ form }}</pre>-->

    <b-container>

    <b-form size="sm" @submit="registerEcdis" v-if="show">
      <b-form-group size="sm" label="Email:" label-for="input-1">
        <b-form-input size="sm" id="input-1" v-model="form.email" type="text" placeholder="" required></b-form-input>
      </b-form-group>
      <b-form-group size="sm" label="Ship name:" label-for="input-2">
        <b-form-input id="input-2" v-model="form.ship_name" type="text" placeholder="" required></b-form-input>
      </b-form-group>
      <b-form-group size="sm" label="IMO:" label-for="input-3">
        <b-form-input id="input-3" v-model="form.ship_imo" type="text" placeholder="" required></b-form-input>
      </b-form-group>
      <b-form-group size="sm" label="Permit:" label-for="input-9">
        <b-form-input id="input-9" v-model="form.permit_main" type="text" placeholder="" required></b-form-input>
      </b-form-group>
      <b-form-group size="sm" label="MMSI:" label-for="input-4">
        <b-form-input id="input-4" v-model="form.ship_mmsi" type="text" placeholder="(optional)"></b-form-input>
      </b-form-group>
      <b-form-group size="sm" label="Ship category:" label-for="input-5">
        <b-form-select id="input-5" v-model="form.ship_category" :options="categories"></b-form-select>
      </b-form-group>
      <b-form-group size="sm" label="ECDIS brand:" label-for="input-6">
        <b-form-select id="input-6" v-model="form.ecdis_brand" :options="brands"></b-form-select>
      </b-form-group>
      <b-form-group size="sm" label="ECIDS OS:" label-for="input-7">
        <b-form-select id="input-7" v-model="form.ecdis_os" :options="systems"></b-form-select>
      </b-form-group>
<!--      <b-form-group size="sm" label="ECIDS type:" label-for="input-8">-->
<!--        <b-form-select id="input-8" v-model="form.ecdis_type" :options="types"></b-form-select>-->
<!--      </b-form-group>-->
      <b-form-group size="sm" label="Backup permit 1:" label-for="input-10">
        <b-form-input id="input-10" v-model="form.permits_backup[0]" type="text" placeholder="(optional)"></b-form-input>
      </b-form-group>
      <b-form-group size="sm" label="Backup permit 2:" label-for="input-11">
        <b-form-input id="input-11" v-model="form.permits_backup[1]" type="text" placeholder="(optional)"></b-form-input>
      </b-form-group>

      <div v-if="$store.state.current.ecdis.edit">
        <b-button type="button" @click="registerEcdis" variant="primary">Save</b-button>&nbsp;
      </div>
      <div v-else>
        <b-button type="button" @click="registerEcdis" variant="primary">Add</b-button>&nbsp;
      </div>
<!--      <b-button type="reset" variant="danger">Reset</b-button>-->

    </b-form>

    <b-modal v-model="modalShow_ok" title="ECDIS registration" ok-only @ok="gotoMyDevices">
      <p>
        Now you can visit the <a href="./devices" v-b-tooltip title="My Devices">My Devices</a> tab and select the ECDIS device to purchase charts.
      </p>
    </b-modal>

    </b-container>

  </div>
</template>

<script>

import myAxios from "@/services/myAxios";
import {eventBus} from "@/main";
import router from "@/router";

export default {
  components: {},
  props: {
    value: Array,
    fields: Array
  },
  data() {
    return {

      form: {
        email: '',
        ship_name: '',
        ship_imo: '',
        ship_mmsi: '',
        ship_category: '',

        ecdis_brand: '',
        ecdis_os: '',
        ecdis_type: '',

        permit_main: '',
        permits_backup: ['',''],
      },
      categories: [{ text: '(optional)', value: '' },
        'Barge',
        'WMS',
        'Vehicle Carrier',
        'Combination',
        'Container',
        'Dredger',
        'Icebreaker',
        'Not a Vessel',
        'Naval Vessel',
        'Passenger Vessel',
        'Pilot',
        'Research Vessel',
        'Reefer',
        'Ro-Ro',
        'Salvage Vessel',
        'Survey Vessel',
        'Tug',
        'Vessel Traffic System',
        'Dry Cargo/Passenger',
        'Fishing',
        'Bulk Carriers',
        'Offshore',
        'Miscellaneous',
        'Tankers',
        'Inland Waterways',
        'Non-Merchant Ships',
        'Non Propelled',
        'Non Ship Structures',],

      brands: [{ text: '(optional)', value: '' },
        'dKart',
        'FURUNO',
        'GEM Elettronica',
        'Kelvin Hughes',
        'Kongsberg Maritime',
        'Maris',
        'NAUDEQ',
        'Navis USA LLC',
        'PC Maritime',
        'Raytheon Anschütz',
        'Seatronx',
        'Sodena',
        'Sperry Marine',
        'TOKIO KEIKI',
        'Transas',
        'JRC',
        'Unknown'],

      systems: [{ text: '(optional)', value: '' },
        'Windows',
        'Linux',
        'Android',],

      types: [{ text: '(optional)', value: '' },
        'Main',
        'Backup',],

      show: true,

      modalShow_ok: false,
      modalShow_nok: false,
    }
  },
  mounted() {

    if (this.$store.state.current.ecdis.edit) {
      this.form.permit_main = this.$store.state.current.ecdis.permit_main;
      this.form.permits_backup[0] = this.$store.state.current.ecdis.permits_backup.at(0);
      this.form.permits_backup[1] = this.$store.state.current.ecdis.permits_backup.at(1);
      this.form.email = this.$store.state.current.ecdis.email;
      this.form.ship_name = this.$store.state.current.ecdis.ship_name;
      this.form.ship_imo = this.$store.state.current.ecdis.ship_imo;
      this.form.ship_mmsi = this.$store.state.current.ecdis.ship_mmsi;
      this.form.ship_category = this.$store.state.current.ecdis.ship_category;
      this.form.ecdis_brand = this.$store.state.current.ecdis.ecdis_brand;
      this.form.ecdis_os = this.$store.state.current.ecdis.ecdis_os;
    }

    // eventBus.$on("edit-ecdis", () => {
    //   this.form.permit_main = "ssss"
    //   console.log("edit ecdis")
    // })
  },
  methods: {
    gotoMyDevices() {
      // window.location.reload();
      router.push({name: 'devices'});
      // let y = setTimeout(() => {
      //   window.location.reload();
      // }, 1000)
    },
    registerEcdis() {

      let userID = this.$store.state.current.profile.user_id;
      let deviceID = this.$store.state.current.profile.device_id;
      let URL = "/api/v1/ecdis/register";

      let data = {
        userPermit: this.form.permit_main,
        notificationEmails: this.form.email,
        backupUserPermits: this.form.permits_backup,
        shipInfo: {name: this.form.ship_name,
          imo: this.form.ship_imo,
          mmsi: this.form.ship_mmsi,
          category: this.form.ship_category,
          ecdisBrand: this.form.ecdis_brand,
          ecdisOs: this.form.ecdis_os,
        },
      }

      if (this.$store.state.current.ecdis.edit) {
        URL = "/api/v1/ecdis/update"
        data["deviceId"] = deviceID;
      }

      console.log(data)

      myAxios.post(URL, data).then(resp => {
        console.log(resp.status)
        if (resp.status === 200) {
          this.modalShow_ok = true;
        } else {
          this.modalShow_nok = true;
          console.log("TODO: error")
        }
      })
      return false
    },
  }
}
</script>

