import Vue from 'vue'
import App from './App.vue'
import VueCookies from 'vue-cookies'
import router from './router'
import store from './store'

import {BootstrapVue, IconsPlugin} from 'bootstrap-vue'

import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
import 'leaflet/dist/leaflet.css';

// Import Bootstrap and BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css';
// import "@fortawesome/fontawesome-free/css/all.min.css";
import { library } from '@fortawesome/fontawesome-svg-core'
import { faChartSimple, faDatabase, faAt, faSearch, faQuestion, faCheck, faStar, faTimes, faCreditCard, faFileInvoice } from '@fortawesome/free-solid-svg-icons'
import { faApple, faAndroid, faWindows } from '@fortawesome/free-brands-svg-icons'
import { faLink } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import ScrollDiv from 'vue-scroll-div';

import MyDate from './components/MyDate.vue';

export const eventBus = new Vue()

Vue.config.productionTip = false

library.add(faApple, faAndroid, faWindows, faLink, faChartSimple, faAt, faSearch, faQuestion, faCheck, faStar, faTimes, faDatabase, faFileInvoice, faCreditCard)

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('my-date', MyDate);

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(require('vue-cookies'))
Vue.use(VueCookies, { expire: '1d'})
Vue.use(ScrollDiv);

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
