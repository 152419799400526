<template>
  <div id="mymap" ref="mm" :style="{ height: height + 'px'}"></div>
</template>

<script>
import "leaflet/dist/leaflet.css";

export default {
  props: ['height', 'map'],
  // computed: {
  //   windowHeight(){
  //     if (this.$route.name === 'data') return window.innerHeight - 150;
  //     return window.innerHeight - 90;
  //   }
  // },
  mounted() {
    //   window.addEventListener("resize", () => {
    //     if (this.$route.name === 'data') this.windowHeight = window.innerHeight - 150;
    //     else this.windowHeight = window.innerHeight - 90;
    //   });

    this.map.init(this.$refs.mm, true, false);
  },
};
</script>

<style scoped>
#mymap {
  border: solid 1px dimgray;
}
</style>