<template>
  <div>

    <b-modal id="modal-primar-error" title="Error" v-model="network.isError" ok-only>
      <p class="my-4">Error {{network.code}}: chart purchasing in temporary unavailable. Try reload the page later. </p>
    </b-modal>

    <b-modal id="modal-viewer" title="Hint" v-model="$store.state.current.hints.showChartsHint" ok-only @ok="okHandler">
      <p class="my-4">This is a Primar maps viewer for non logined users</p>
      <p class="my-4">For any questions please write us to <a href="mailto:support@mkart.fi">support@mkart.fi</a> </p>
    </b-modal>

    <b-container fluid>
      <b-row>
        <b-col cols="12">
          <b-badge variant="light">Primar maps viewer</b-badge>
          <br><br>
          <div>
            <div>
              <my-map :map=map :show-route=haveRoute :height="mapHeight"></my-map>
            </div>
          </div>
        </b-col>

      </b-row>
    </b-container>
  </div>
</template>

<script>
import Products from "@/views/charts/Products";
import * as utils from "@/utils/utils";
import {ChartsLeaf} from "../../my-leaf/charts-leaf";
import {file} from "paths.macro";
import {eventBus} from "@/main";
import ViewerMap from "@/views/viewer/ViewerMap.vue";

export default {
  components: {MyMap: ViewerMap},
  data() {
    return {
      network: {
        isError: false,
        code: '',
      },

      map: new ChartsLeaf(true),
      haveRoute: false,
      log: "",
      chart_types: [
        {title: "Products"},
        {title: "S63/Primar"},
      ],
      licensing: {},
      selected: 0,
      mapHeight: window.innerHeight / 1.2,
      bagSelected: false
    }
  },
  computed: {
    is_primar_loading() {
      return this.$store.state.current.loading.primarInfo;
    },
    is_drawing() {
      return this.$store.state.current.loading.drawing;
    },
    charts() {
      return this.$store.state.charts.s63.items;
    },
  },
  methods: {
    productsByProduct(product) {
      let str = [];

      if (product) {
        product.forEach((prod) => {
          // str += prod.type + ", ";
          str.push(prod.type);
        });
      }
      return str;
    },
    formatDate(date) {
      return utils.formatDate(date).date;
    },
    onOrderCreation(payload) {
      let keys = [];

      this.charts.forEach(item => {
        console.log(item)
        keys.push({id: item.name, monthCount: "" + item.period, price: "" + item.price});
      })
      this.$store.dispatch('addS63Order', {keys: keys, price: payload.price, uuid: payload.uuid});
      this.$store.dispatch('getENCs').then(() => {
        this.$store.dispatch("getS63Summary", {
          device_id: this.$store.state.current.profile.device_id,
          user_id: this.$store.state.current.profile.user_id,
        }).then(() => {
          console.log('init draw all cells from', file)

          this.map.drawAllCells(this.$store.state.charts.s63.primar_map_info);
        })
      })
    },
    allProducts() {
      let all_prods = this.$store.state.manage.products;
      let prods = this.$store.state.charts.profile.products;

      var dataArray = [];

      prods.forEach((inst) => {
        // console.log(inst.productInfo.productId)
        dataArray.push(inst.productInfo.productId);

        all_prods = all_prods.filter(function (e) {
          return e["productId"] != inst.productInfo.productId && e["licenseType"] != "corp";
        });
      });

      return all_prods;

    },
    charts_collection() {
      return this.$store.state.manage.charts_collection;
    },
    productContent(product) {
      let str = "";

      if (product.enc)
        str += "ENCs, ";
      if (product.s102)
        str += "S102, ";
      if (product.poi)
        str += "POI, ";
      if (product.ntm)
        str += "NTM, ";
      if (product.ais)
        str += "AIS, ";
      if (product.weather)
        str += "Weather, ";
      if (product.navMsg)
        str += "Nav.Messages, ";
      if (product.noaaWeather)
        str += "NOAA Weather, ";
      if (product.pollution)
        str += "Pollution, ";

      str = utils.truncStrng(str, 20);

      return str;
    },
    isOutdated(date, type) {
      if (type.toLowerCase() === "free") {
        return "success";
      }
      let days = utils.daysOldWithSign(date);
      return days <= 0 ? "danger" : "success";
    },
    okHandler() {
      this.$store.commit("current/setHintsCharts", false)
    },
  },
  mounted() {
    eventBus.$on('network-error', (value) => {
      this.network.isError = true;
      this.network.code = value;
    })
  },
  beforeDestroy() {
    this.map.clearCellslayer()
    clearInterval(this.orto);
    this.$store.commit("setAllSummaryClear");
  }
}
</script>

<style scoped>
</style>
