import myAxios from './myAxios';
import qs from "qs";
import store, {clearPersistanceStorage, test12} from "@/store";

export {
    login,
    login_by_token,
    logout,
    refreshUser,
    register,
    loginWithMconnect
};

function login(username, password) {
    const params = {
        login: username,
        password: password,
    };

    return myAxios.post('api/v1/login/', params,
        {
            headers: {
                'content-type': 'application/json',
            }
        }
    );
}
function loginWithMconnect (mconnectToken) {
    return myAxios.get('api/v1/login?token=' + mconnectToken)
}

    function login_by_token(token) {
        return myAxios.get('api/v1/login?token=' + token
            // {
            //     headers: {
            //         'content-type': 'application/json',
            //     }
            // }
        )
    }

    function register(email, password, userType, applicationTypeId) {
        const params = {
            email: email,
            userType: userType,
            password: password,
            applicationTypeId: applicationTypeId
        };

        return myAxios.post('api/v1/auth/register', params,
            {
                headers: {
                    'content-type': 'application/json',
                }
            }
        );
    }


    function logout() {
        localStorage.removeItem('user');
        localStorage.removeItem('selected-device');
        store.commit("leaflet/resetStorage")
    }

    function refreshUser() {
        return myAxios.get('/api/v1/manage/test');
    }