<template>
  <span>{{ mydate }}</span>
</template>

<script>
import moment from "moment";

export default {
  props: {
    date: null,
  },
  computed: {
    mydate() {
      if (this.date) {
        return moment(this.date).format("YYYY-MM-DD");
      }
      return "unlimited";
    },
  },
};
</script>